import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Tooltip,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import clsx from "clsx";
import { useCallback, useContext, useMemo, useState } from "react";
import CryptoIcon from "../../../components/CryptoIcon";
import EDIT_ICON from "../../../statics/images/fi_edit-3.svg";
import {
  CelciusTx,
  ClaimContext,
  ScheduleFLineAccount,
} from "../ClaimContextProvider";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import moment from "moment";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { step_3 } from "./schema";
import * as yup from "yup";
import { baseURL } from "../../../api/client";
import { uploadImage } from "../../../common/utils";

const renderCelciusAccountBalance = (
  account: ScheduleFLineAccount,
  accountType: "Earn" | "Custody" | "Witheld" | "Borrow",
  prices: {
    coin: string;
    price: string;
  }[],
  editHandler: (index: number | null) => void,
  form: FormInstance<any>
) => {
  return (
    <>
      {account.length ? (
        <table className={styles.edit_table}>
          <thead className="hidden sm:table-header-group">
            <tr>
              <th>Coin</th>
              <th>Quantity</th>
              <th>USD Value as of July 13, 2022</th>
              <th>Claim Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {account.map((item, index) => {
              const mappingToken = prices.find(
                (price) => price.coin === item.token
              );
              const icon = mappingToken?.coin?.toLowerCase() ?? "btc";
              const price = mappingToken?.price;

              return (
                <tr>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-t border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-lg !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Coin
                      </div>
                      <div className="text-sm text-grey-400 flex space-x-2 items-center">
                        <CryptoIcon name={icon} />
                        <span>{item.token}</span>
                      </div>
                    </div>
                  </td>

                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Quantity
                      </div>
                      <div className="text-sm text-grey-400">
                        {(+item.value).toFixed(5)}
                      </div>
                    </div>
                  </td>

                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        USD Value as of July 13, 2022
                      </div>
                      <div className="text-sm text-grey-400">
                        ${+(price ?? 0)}
                      </div>
                    </div>
                  </td>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Claim Value
                      </div>
                      <div className="text-sm text-grey-400">
                        ${(+item.value * +(price ?? 0)).toFixed(5)}
                      </div>
                    </div>
                  </td>

                  <td className="block sm:table-cell !py-2 border-grey-100 border sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-lg">
                    <div className="flex items-center justify-center">
                      <img
                        className="cursor-pointer hidden sm:block"
                        src={EDIT_ICON}
                        alt="Edit icon"
                        onClick={() => {
                          editHandler(index);
                          form.setFieldValue("coin", item.token);
                          form.setFieldValue("quantity", item.value);
                        }}
                      />

                      <div
                        className="text-sm text-primary-400 underline cursor-pointer block sm:hidden"
                        onClick={() => {
                          editHandler(index);
                          form.setFieldValue("coin", item.token);
                          form.setFieldValue("quantity", item.value);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.empty_message}>
          There are no entries yet. Click the button below to add a new entry.
        </p>
      )}

      <div className={styles.total}>
        <p>Total Celsius {accountType} Balances</p>
        <p className="text-lg">
          $
          {account.reduce((previousValue, currentValue: any) => {
            return (
              previousValue +
              Number(currentValue.value ?? null) *
                Number(
                  prices.find((price) => price.coin === currentValue.token)
                    ?.price ?? null
                )
            );
          }, 0)}
        </p>
      </div>

      <Button
        className={styles.add_button}
        type="primary"
        onClick={() => {
          form.resetFields();
          editHandler(null);
        }}
      >
        Add new
      </Button>
    </>
  );
};

const renderCelciusAccountTx = (
  account: CelciusTx,
  prices: {
    coin: string;
    price: string;
  }[],
  editHandler: (index: number | null) => void,
  form: FormInstance<any>
) => {
  return (
    <>
      {account.length ? (
        <table className={styles.edit_table}>
          <thead className="hidden sm:table-header-group">
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Token</th>
              <th>Value in USD</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="space-y-2 sm:space-y-0">
            {account.map((item, index) => {
              const mappingToken = prices.find(
                (price) => price.coin === item.token
              );
              const icon = mappingToken?.coin?.toLowerCase() ?? "btc";

              return (
                <tr>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-t border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-lg !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Date
                      </div>
                      <div className="text-sm text-grey-400">{item.date}</div>
                    </div>
                  </td>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Type
                      </div>
                      <div className="text-sm text-grey-400">{item.type}</div>
                    </div>
                  </td>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Token
                      </div>
                      <div className="text-sm text-grey-400 flex items-center space-x-2">
                        <CryptoIcon name={icon} />
                        <span>{item.token}</span>
                      </div>
                    </div>
                  </td>
                  <td className="block sm:table-cell !py-2 !border-b-transparent border-grey-100 border-l border-r sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-none">
                    <div className="flex items-center justify-between">
                      <div className="block sm:hidden font-semibold text-xs text-grey-500">
                        Value in USD
                      </div>
                      <div className="text-sm text-grey-400">
                        ${(+item.value).toFixed(5)}
                      </div>
                    </div>
                  </td>

                  <td className="block sm:table-cell !py-2 border-grey-100 border sm:!border-b-grey-100 sm:!border-t-0 sm:!border-r-0 sm:!border-l-0 !rounded-t-none !rounded-b-lg">
                    <div className="flex items-center justify-center">
                      <img
                        className="cursor-pointer hidden sm:block"
                        src={EDIT_ICON}
                        alt="Edit icon"
                        onClick={() => {
                          editHandler(index);
                          form.setFieldValue("date", dayjs(item.date));
                          form.setFieldValue("type", item.type);
                          form.setFieldValue("token", item.token);
                          form.setFieldValue("value", item.value);
                        }}
                      />

                      <div
                        className="text-sm text-primary-400 underline cursor-pointer block sm:hidden"
                        onClick={() => {
                          editHandler(index);
                          form.setFieldValue("date", dayjs(item.date));
                          form.setFieldValue("type", item.type);
                          form.setFieldValue("token", item.token);
                          form.setFieldValue("value", item.value);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.empty_message}>
          There are no entries yet. Click the button below to add a new entry.
        </p>
      )}

      <Button
        className={styles.add_button}
        type="primary"
        onClick={() => {
          form.resetFields();
          editHandler(null);
        }}
      >
        Add new
      </Button>
    </>
  );
};

const Step3 = () => {
  const {
    prices,
    celciusAccountBalance,
    setCelciusAccountBalance,
    celciusAccountTx,
    setCelciusAccountTx,
    handleBack,
    handleNext,
    celciusScheduleData,
    useYupValidationResolver,
    formData,
    handleUpdateForm,
  } = useContext(ClaimContext);
  console.log("===>", formData);
  type FormData = yup.InferType<typeof step_3>;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(step_3),
    defaultValues: {
      celsius_email: formData.celsius_email,
      celsius_address: formData.celsius_address,
      celsius_relationship: formData.celsius_relationship,
      transaction_earn_type: formData.transaction_earn_type,
      transaction_custody_type: formData.transaction_custody_type,
      celsius_excl_earn: formData.celsius_excl_earn,
      celsius_pdf_earn: formData.celsius_pdf_earn,
      celsius_excl_custody: formData.celsius_excl_custody,
      celsius_pdf_custody: formData.celsius_pdf_custody,
    },
  });

  const transactionEarnType = useWatch({
    control,
    name: "transaction_earn_type",
  });
  const transactionCustodyType = useWatch({
    control,
    name: "transaction_custody_type",
  });
  const exelEarn = useWatch({
    control,
    name: "celsius_excl_earn",
  });
  const exelCustody = useWatch({
    control,
    name: "celsius_excl_custody",
  });
  const pdfCustody = useWatch({
    control,
    name: "celsius_pdf_custody",
  });
  const pdfEarn = useWatch({
    control,
    name: "celsius_pdf_earn",
  });

  const currentExelEarn = useMemo(
    () => (exelEarn ? (exelEarn as Record<string, any>).fileList || [] : []),
    [exelEarn]
  );

  const currentExelCustody = useMemo(
    () =>
      exelCustody ? (exelCustody as Record<string, any>).fileList || [] : [],
    [exelCustody]
  );

  const currentPdfEarn = useMemo(
    () => (pdfEarn ? (pdfEarn as Record<string, any>).fileList || [] : []),
    [pdfEarn]
  );

  const currentPdfCustody = useMemo(
    () =>
      pdfCustody ? (pdfCustody as Record<string, any>).fileList || [] : [],
    [pdfCustody]
  );

  const [formEditAccountBalances] = Form.useForm();
  const [formEditTxAccount] = Form.useForm();

  const coinValueEditAccountBalances = Form.useWatch(
    "coin",
    formEditAccountBalances
  );
  const quantityValueEditAccountBalances = Form.useWatch(
    "quantity",
    formEditAccountBalances
  );

  const [editAccountBalance, setEditAccountBalance] = useState<{
    type:
      | "celsius_earn_balances"
      | "celsius_custody_balances"
      | "celsius_witheld_balances"
      | "celsius_borrow_balances";
    index: number | null;
  } | null>(null);

  const [editAccountTx, setEditAccountTx] = useState<{
    type: "celsius_tx_earn" | "celsius_tx_custody";
    index: number | null;
  } | null>(null);

  const handleEditAccountBanlances = () => {
    setCelciusAccountBalance((prevState) => {
      const updateBalance = {
        token: `${formEditAccountBalances.getFieldValue("coin")}`,
        value: `${formEditAccountBalances.getFieldValue("quantity")}`,
      };

      switch (editAccountBalance?.type) {
        case "celsius_earn_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.earn_account?.[editAccountBalance.index])
              prevState?.earn_account?.splice(
                editAccountBalance.index,
                1,
                updateBalance
              );
          } else {
            prevState?.earn_account?.push(updateBalance);
          }
          return {
            ...prevState,
          };
        }

        case "celsius_custody_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.custody_account?.[editAccountBalance.index])
              prevState?.custody_account?.splice(
                editAccountBalance.index,
                1,
                updateBalance
              );
          } else {
            prevState?.custody_account?.push(updateBalance);
          }

          return {
            ...prevState,
          };
        }

        case "celsius_witheld_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.withheld_account?.[editAccountBalance.index])
              prevState?.withheld_account?.splice(
                editAccountBalance.index,
                1,
                updateBalance
              );
          } else {
            prevState?.withheld_account?.push(updateBalance);
          }

          return {
            ...prevState,
          };
        }

        case "celsius_borrow_balances": {
          if (editAccountBalance?.index !== null) {
            if (
              prevState?.collateral_on_loan_receivable?.[
                editAccountBalance.index
              ]
            )
              prevState?.collateral_on_loan_receivable?.splice(
                editAccountBalance.index,
                1,
                updateBalance
              );
          } else {
            prevState?.collateral_on_loan_receivable?.push(updateBalance);
          }

          return {
            ...prevState,
          };
        }

        default:
          return {
            ...prevState,
          };
      }
    });
    setEditAccountBalance(null);
  };

  const handleRemoveAccountBalance = () => {
    setCelciusAccountBalance((prevState) => {
      switch (editAccountBalance?.type) {
        case "celsius_earn_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.earn_account?.[editAccountBalance.index])
              prevState?.earn_account?.splice(editAccountBalance.index, 1);
          }

          return {
            ...prevState,
          };
        }
        case "celsius_custody_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.custody_account?.[editAccountBalance.index])
              prevState?.custody_account?.splice(editAccountBalance.index, 1);
          }

          return {
            ...prevState,
          };
        }
        case "celsius_witheld_balances": {
          if (editAccountBalance?.index !== null) {
            if (prevState?.withheld_account?.[editAccountBalance.index])
              prevState?.withheld_account?.splice(editAccountBalance.index, 1);
          }

          return {
            ...prevState,
          };
        }
        case "celsius_borrow_balances": {
          if (editAccountBalance?.index !== null) {
            if (
              prevState?.collateral_on_loan_receivable?.[
                editAccountBalance.index
              ]
            )
              prevState?.collateral_on_loan_receivable?.splice(
                editAccountBalance.index,
                1
              );
          }

          return {
            ...prevState,
          };
        }

        default:
          return {
            ...prevState,
          };
      }
    });
    setEditAccountBalance(null);
  };

  const handleEditTxAccount = () => {
    setCelciusAccountTx((prevState) => {
      const updateTx = {
        date: moment(new Date(formEditTxAccount.getFieldValue("date"))).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        type: formEditTxAccount.getFieldValue("type"),
        token: formEditTxAccount.getFieldValue("token"),
        value: formEditTxAccount.getFieldValue("value"),
      };

      switch (editAccountTx?.type) {
        case "celsius_tx_earn": {
          if (editAccountTx?.index !== null) {
            if (prevState?.earn_account?.[editAccountTx.index])
              prevState?.earn_account?.splice(editAccountTx.index, 1, updateTx);
          } else {
            prevState?.earn_account?.push(updateTx);
          }

          return {
            ...prevState,
          };
        }

        case "celsius_tx_custody": {
          if (editAccountTx?.index !== null) {
            if (prevState?.custody_account?.[editAccountTx.index])
              prevState?.custody_account?.splice(
                editAccountTx.index,
                1,
                updateTx
              );
          } else {
            prevState?.custody_account?.push(updateTx);
          }

          return {
            ...prevState,
          };
        }

        default:
          return {
            ...prevState,
          };
      }
    });
    setEditAccountTx(null);
  };

  const handleRemoveTxAccount = () => {
    setCelciusAccountTx((prevState) => {
      switch (editAccountTx?.type) {
        case "celsius_tx_earn": {
          if (editAccountTx?.index !== null) {
            if (prevState?.earn_account?.[editAccountTx.index])
              prevState?.earn_account?.splice(editAccountTx.index, 1);
          }

          return {
            ...prevState,
          };
        }

        case "celsius_tx_custody": {
          if (editAccountTx?.index !== null) {
            if (prevState?.custody_account?.[editAccountTx.index])
              prevState?.custody_account?.splice(editAccountTx.index, 1);
          }

          return {
            ...prevState,
          };
        }

        default:
          return {
            ...prevState,
          };
      }
    });

    setEditAccountTx(null);
  };

  const values = useWatch({ control });

  const [defaultFileList, setDefaultFileList] = useState({
    celsius_tx_earn: [],
    celsius_pdf_earn: [],
    celsius_pdf_custody: [],
    celsius_excl_custody: []
  } as any);
  
  const handleOnChange = ({ file, fileList, event }: any, field: string) => {
    setDefaultFileList({...defaultFileList, [field]: fileList});
  };

  const handleNextStep = useCallback(
    (values: Record<string, any>) => {
      handleUpdateForm(values);
      handleNext();
    },
    [handleNext, handleUpdateForm]
  );

  const handleBackStep = useCallback(() => {
    handleUpdateForm(values);
    handleBack();
  }, [handleBack, handleUpdateForm, values]);

  return (
    <>
      <Form onFinish={handleSubmit(handleNextStep)} layout="vertical">
        <div className={clsx(styles.panel_container)}>
          <Typography.Text className="font-semibold text-black text-2xl !px-4 sm:!px-10">
            Celsius Details
          </Typography.Text>

          <Form.Item
            label="Email associated with registered Celsius Account"
            required
            className="!px-4 sm:!px-10"
          >
            <Controller
              name="celsius_email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Input {...field} status={errors.celsius_email && "error"} />
              )}
            />
            <Typography.Text type="danger">
              {errors.celsius_email?.message?.toString()}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            label="Physical Address associated with registered Celsius Account "
            required
            className="!px-4 sm:!px-10"
          >
            <Controller
              name={"celsius_address"}
              control={control}
              render={({ field }) => (
                <Input {...field} status={errors.celsius_address && "error"} />
              )}
            />
            <Typography.Text type="danger">
              {errors.celsius_address?.message?.toString()}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            label="What was your relationship with Celsius?"
            required
            className="!px-4 sm:!px-10"
          >
            <Controller
              name={"celsius_relationship"}
              control={control}
              render={({ field }) => (
                <div className="flex flex-col gap-1">
                  <Checkbox.Group
                    {...field}
                    options={[
                      { label: "Depositor", value: "Depositor" },
                      { label: "Vendor", value: "Vendor" },
                      { label: "Employee", value: "Employee" },
                      { label: "Consultant", value: "Consultant" },
                      {
                        label: "UCC Claims Stipulation Defendant",
                        value: "UCC Claims Stipulation Defendant",
                      },
                      {
                        label: "Current or Former Director",
                        value: "Current or Former Director",
                      },
                      { label: "Equity Holder", value: "Equity Holder" },
                      {
                        label: "Independent Contractor",
                        value: "Independent Contractor",
                      },
                      {
                        label: "Relative of any of the above except Debtor",
                        value: "Relative of any of the above except Debtor",
                      },
                    ]}
                    // value={checkedList}
                    // onChange={onChange}
                  />
                  <Typography.Text type="danger">
                    {errors.celsius_relationship?.message?.toString()}
                  </Typography.Text>
                </div>
              )}
            />
          </Form.Item>

          <hr className="border-[#EDEDED]" />
          {(!formData.claim_link ||
            !celciusScheduleData._id ||
            formData.is_soa_available === "No" ||
            formData.is_soa_amount_agreed === "No") && (
            <>
              <Typography.Text className="font-semibold text-[#111111] text-2xl !px-4 sm:!px-10">
                Claim Amount
              </Typography.Text>

              <div className="!px-4 sm:!px-10">
                <div className={styles.celsius_account_balances}>
                  <div className={styles.title}>
                    <span>1</span>&nbsp;&nbsp;
                    <span>Enter Your Celsius Earn Account Token Balances</span>
                  </div>
                  {renderCelciusAccountBalance(
                    celciusAccountBalance.earn_account,
                    "Earn",
                    prices,
                    (index) => {
                      setEditAccountBalance({
                        type: "celsius_earn_balances",
                        index: index,
                      });
                    },
                    formEditAccountBalances
                  )}
                </div>
              </div>

              <div className="!px-4 sm:!px-10">
                <div className={styles.celsius_account_balances}>
                  <div className={styles.title}>
                    <span>2</span>&nbsp;&nbsp;
                    <span>
                      Enter Your Celsius Custody Account Token Balances
                    </span>
                  </div>
                  {renderCelciusAccountBalance(
                    celciusAccountBalance.custody_account,
                    "Custody",
                    prices,
                    (index) => {
                      setEditAccountBalance({
                        type: "celsius_custody_balances",
                        index: index,
                      });
                    },
                    formEditAccountBalances
                  )}
                </div>
              </div>

              <div className="!px-4 sm:!px-10">
                <div className={styles.celsius_account_balances}>
                  <div className={styles.title}>
                    <span>3</span>&nbsp;&nbsp;
                    <span>
                      Enter Your Celsius Witheld Account Token Balances
                    </span>
                  </div>
                  {renderCelciusAccountBalance(
                    celciusAccountBalance.withheld_account,
                    "Witheld",
                    prices,
                    (index) => {
                      setEditAccountBalance({
                        type: "celsius_witheld_balances",
                        index: index,
                      });
                    },
                    formEditAccountBalances
                  )}
                </div>
              </div>

              <div className="!px-4 sm:!px-10">
                <div className={styles.celsius_account_balances}>
                  <div className={styles.title}>
                    <span>4</span>&nbsp;&nbsp;
                    <span>
                      Enter Your Celsius Borrow Account Token Balances
                    </span>
                  </div>
                  {renderCelciusAccountBalance(
                    celciusAccountBalance.collateral_on_loan_receivable,
                    "Borrow",
                    prices,
                    (index) => {
                      setEditAccountBalance({
                        type: "celsius_borrow_balances",
                        index: index,
                      });
                    },
                    formEditAccountBalances
                  )}
                </div>
              </div>
              <hr className="border-[#EDEDED]" />
            </>
          )}

          <Typography.Text className="font-semibold text-[#111111] text-2xl !px-4 sm:!px-10">
            Transactions on Earn Account
            <br />
            <Typography.Text className="font-normal text-[#666666] text-md">
              (Maximum 15 transactions for Manual){" "}
              <Typography.Text className="text-[#F04438]">*</Typography.Text>
            </Typography.Text>
          </Typography.Text>

          <Form.Item required className="!px-4 sm:!px-10">
            <Controller
              control={control}
              name="transaction_earn_type"
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  options={[
                    { label: "Manual", value: "Manual" },
                    { label: "Upload File", value: "Upload File" },
                    { label: "No Transaction", value: "No Transaction" },
                  ]}
                  defaultValue={"Manual"}
                />
              )}
            />
          </Form.Item>

          {transactionEarnType === "No Transaction" && (
            <div className="!px-4 sm:!px-10">
              <div className="bg-[#FFEECB] px-[16px] py-[12px] rounded-[8px] text-sm font-medium">
                <p className="text-[#DB9712] m-0">
                  Only select this if you have no transactions between April 14,
                  2022 & July 12, 2022 inclusive of both dates
                </p>
              </div>
            </div>
          )}

          {transactionEarnType === "Manual" && (
            <div className="!px-4 sm:!px-10">
              <div className={styles.celsius_account_balances}>
                <div className={styles.title}>
                  <span>1</span>&nbsp;&nbsp;
                  <span>
                    Entries for withdrawal/deposit history for earn account
                    <br />
                    (between April 14, 2022 & July 12, 2022 inclusive of both
                    dates)
                  </span>
                </div>
                {renderCelciusAccountTx(
                  celciusAccountTx.earn_account,
                  prices,
                  (index) => {
                    setEditAccountTx({
                      type: "celsius_tx_earn",
                      index: index,
                    });
                  },
                  formEditTxAccount
                )}
                <div className={styles.total}>
                  <p>Earn Preference Risk</p>
                  <p className="text-lg">
                    <b>
                      {Math.max(
                        celciusAccountTx.earn_account
                          .filter(({ date, token, type, value }) => {
                            return !!date && !!token && !!type && !!value;
                          })
                          .sort(
                            (prev, next) =>
                              moment(prev.date).unix() -
                              moment(next.date).unix()
                          )
                          .reduce((previousValue, { type, value }) => {
                            return type === "Withdraw"
                              ? previousValue + Number(value)
                              : Math.max(previousValue - Number(value), 0);
                          }, 0),
                        0
                      )}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          )}

          {transactionEarnType === "Upload File" && (
            <Form.Item
              label={
                <>
                  Excel for withdrawal/deposit history for Earn Account,
                  <br />
                  (between April 14, 2022 & July 12, 2022 inclusive of both
                  dates)
                </>
              }
              required
              className="!px-4 sm:!px-10"
            >
              <div className="flex flex-col gap-1">
                  <Dragger
                    listType="picture"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    name="file"
                    customRequest={(options) => uploadImage(options, setValue, 'celsius_excl_earn')}
                    onChange={(file) => handleOnChange(file, 'celsius_excl_earn')}
                    defaultFileList={
                      defaultFileList.celsius_excl_earn?.length ? defaultFileList.celsius_excl_earn : formData.celsius_excl_earn?.fileList
                    }
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drop your file here, or browse
                    </p>
                    <p className="ant-upload-hint">
                      Support CSV & Excel file
                    </p>
                  </Dragger>
                  <Typography.Text type="danger">
                    {errors.celsius_excl_earn?.message?.toString()}
                  </Typography.Text>
                </div>
            </Form.Item>
          )}

          {transactionEarnType !== "No Transaction" && (
            <Form.Item
              label={
                <>
                  PDF for withdrawal/deposit history for Earn Account
                  <br />
                  (between April 14, 2022 & July 12, 2022 inclusive of both
                  dates)
                </>
              }
              required
              className="!px-4 sm:!px-10"
            >
              <Form.Item required>
                    <div className="flex flex-col gap-1">
                      <Dragger
                        listType="picture"
                        accept=".pdf"
                        customRequest={(options) => uploadImage(options, setValue, 'celsius_pdf_earn')}
                        onChange={(file) => handleOnChange(file, 'celsius_pdf_earn')}
                        defaultFileList={
                          defaultFileList.celsius_pdf_earn?.length ? defaultFileList.celsius_pdf_earn : formData.celsius_pdf_earn?.fileList
                        }
                        name="file"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Drop your file here, or browse
                        </p>
                        <p className="ant-upload-hint">
                          Support PDF
                        </p>
                      </Dragger>
                      <Typography.Text type="danger">
                        {errors.celsius_pdf_earn?.message?.toString()}
                      </Typography.Text>
                    </div>
              </Form.Item>
            </Form.Item>
          )}

          <hr className="border-[#EDEDED]" />

          <Typography.Text className="font-semibold text-[#111111] text-2xl !px-4 sm:!px-10">
            Transactions on Custody Account
            <br />
            <Typography.Text className="font-normal text-[#666666] text-md">
              (Maximum 15 transactions for Manual){" "}
              <Typography.Text className="text-[#F04438]">*</Typography.Text>
            </Typography.Text>
          </Typography.Text>

          <Form.Item required className="!px-4 sm:!px-10">
            <Controller
              control={control}
              name="transaction_custody_type"
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  options={[
                    { label: "Manual", value: "Manual" },
                    { label: "Upload File", value: "Upload File" },
                    { label: "No Transaction", value: "No Transaction" },
                  ]}
                  defaultValue={"Manual"}
                />
              )}
            />
          </Form.Item>

          {transactionCustodyType === "No Transaction" && (
            <div className="!px-4 sm:!px-10">
              <div className="bg-[#FFEECB] px-[16px] py-[12px] rounded-[8px] text-sm font-medium">
                <p className="text-[#DB9712] m-0">
                  Only select this if you have no transactions between April 14,
                  2022 & July 12, 2022 inclusive of both dates
                </p>
              </div>
            </div>
          )}

          {transactionCustodyType === "Manual" && (
            <div className="!px-4 sm:!px-10">
              <div className={styles.celsius_account_balances}>
                <div className={styles.title}>
                  <span>1</span>&nbsp;&nbsp;
                  <span>
                    Entries for withdrawal/deposit history for custody account
                    <br />
                    (between April 14, 2022 & July 12, 2022 inclusive of both
                    dates)
                  </span>
                </div>
                {renderCelciusAccountTx(
                  celciusAccountTx.custody_account,
                  prices,
                  (index) => {
                    setEditAccountTx({
                      type: "celsius_tx_custody",
                      index: index,
                    });
                  },
                  formEditTxAccount
                )}
                <div className={styles.total}>
                  <p>Custody Preference Risk</p>
                  <p className="text-lg">
                    <b>
                      {Math.max(
                        celciusAccountTx.custody_account
                          .filter(({ date, token, type, value }) => {
                            return !!date && !!token && !!type && !!value;
                          })
                          .sort(
                            (prev, next) =>
                              moment(prev.date).unix() -
                              moment(next.date).unix()
                          )
                          .reduce((previousValue, { type, value }) => {
                            return type === "Withdraw"
                              ? previousValue + Number(value)
                              : Math.max(previousValue - Number(value), 0);
                          }, 0),
                        0
                      )}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          )}

          {transactionCustodyType === "Upload File" && (
            <Form.Item
              label={
                <>
                  Excel for withdrawal/deposit history for Custody Account,
                  <br />
                  (between April 14, 2022 & July 12, 2022 inclusive of both
                  dates)
                </>
              }
              required
              className="!px-4 sm:!px-10"
            >
             
                  <div className="flex flex-col gap-1">
                    <Dragger
                      listType="picture"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      customRequest={(options) => uploadImage(options, setValue, 'celsius_excl_custody')}
                      name="file"
                      onChange={(file) => handleOnChange(file, 'celsius_excl_custody')}
                      defaultFileList={
                        defaultFileList.celsius_excl_custody?.length ? defaultFileList.celsius_excl_custody : formData.celsius_excl_custody?.fileList
                      }
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drop your word here, or browse
                      </p>
                      <p className="ant-upload-hint">
                        Support CSV & Excel file
                      </p>
                    </Dragger>
                    <Typography.Text type="danger">
                      {errors.celsius_excl_custody?.message?.toString()}
                    </Typography.Text>
                  </div>
            
            </Form.Item>
          )}

          {transactionCustodyType !== "No Transaction" && (
            <Form.Item
              label={
                <>
                  PDF for withdrawal/deposit history for Custody Account
                  <br />
                  (between April 14, 2022 & July 12, 2022 inclusive of both
                  dates)
                </>
              }
              required
              className="!px-4 sm:!px-10"
            >
              
                  <div className="flex flex-col gap-1">
                    <Dragger
                      listType="picture"
                      accept=".pdf"
                      customRequest={(options) => uploadImage(options, setValue, 'celsius_pdf_custody')}
                      name="file"
                      onChange={(file) => handleOnChange(file, 'celsius_pdf_custody')}
                      defaultFileList={
                        defaultFileList.celsius_pdf_custody?.length ? defaultFileList.celsius_pdf_custody : formData.celsius_pdf_custody?.fileList
                      }
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drop your file here, or browse
                      </p>
                      <p className="ant-upload-hint">Support PDF</p>
                    </Dragger>
                    <Typography.Text type="danger">
                      {errors.celsius_pdf_custody?.message?.toString()}
                    </Typography.Text>
                  </div>
                
            </Form.Item>
          )}

          <div className="!px-4 sm:!px-10">
            <div className={styles.account_pref_risk}>
              <p>Account Preference Risk</p>
              <p>
                {Math.max(
                  celciusAccountTx.earn_account
                    .filter(({ date, token, type, value }) => {
                      return !!date && !!token && !!type && !!value;
                    })
                    .sort(
                      (prev, next) =>
                        moment(prev.date).unix() - moment(next.date).unix()
                    )
                    .reduce((previousValue, { type, value }) => {
                      return type === "Withdraw"
                        ? previousValue + Number(value)
                        : Math.max(previousValue - Number(value), 0);
                    }, 0),
                  0
                ) +
                  Math.max(
                    celciusAccountTx.custody_account
                      .filter(({ date, token, type, value }) => {
                        return !!date && !!token && !!type && !!value;
                      })
                      .sort(
                        (prev, next) =>
                          moment(prev.date).unix() - moment(next.date).unix()
                      )
                      .reduce((previousValue, { type, value }) => {
                        return type === "Withdraw"
                          ? previousValue + Number(value)
                          : Math.max(previousValue - Number(value), 0);
                      }, 0),
                    0
                  )}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.navigation}>
          <Button onClick={handleBackStep}>Back</Button>
          <Button htmlType="submit">Next</Button>
        </div>
      </Form>

      <Modal
        title={
          <Title level={2}>
            {editAccountBalance?.index === null
              ? "Add New Entry"
              : "Entry Detail"}
          </Title>
        }
        open={editAccountBalance !== null}
        onCancel={() => {
          setEditAccountBalance(null);
        }}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles["modal-container"]}
      >
        <Form
          layout="vertical"
          className={styles["form-container"]}
          form={formEditAccountBalances}
          onFinish={() => handleEditAccountBanlances()}
        >
          <Form.Item
            className={styles["form-container__item"]}
            label="Coin"
            name="coin"
            rules={[{ required: true, message: "Please input your coin!" }]}
          >
            <Select
              options={prices.map(({ coin }) => ({
                label: coin,
                value: coin,
              }))}
            />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Quantity"
            name="quantity"
            rules={[{ required: true, message: "Please input your quantity!" }]}
          >
            <InputNumber />
          </Form.Item>

          {!!coinValueEditAccountBalances && (
            <>
              <Form.Item required label="USD Value as of July 13, 2022">
                <Typography.Text className="text-base">
                  {Number(
                    prices.find(
                      (price) => price.coin === coinValueEditAccountBalances
                    )?.price ?? null
                  )}
                </Typography.Text>
              </Form.Item>
              {!!quantityValueEditAccountBalances && (
                <Form.Item required label="USD Value as of July 13, 2022">
                  <Typography.Text className="text-2xl font-semibold">
                    $
                    {Number(quantityValueEditAccountBalances ?? null) *
                      Number(
                        prices.find(
                          (price) => price.coin === coinValueEditAccountBalances
                        )?.price ?? null
                      )}
                  </Typography.Text>
                </Form.Item>
              )}
            </>
          )}

          <Form.Item className={styles["form-container__item"]}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            {editAccountBalance?.index !== null && (
              <Button type="primary" onClick={handleRemoveAccountBalance}>
                Remove
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <Title level={2}>
            {editAccountTx?.index === null
              ? "Add New Transactions"
              : "Transactions Detail"}
          </Title>
        }
        open={editAccountTx !== null}
        onCancel={() => {
          setEditAccountTx(null);
        }}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles["modal-container"]}
      >
        <Form
          layout="vertical"
          className={styles["form-container"]}
          form={formEditTxAccount}
          onFinish={() => handleEditTxAccount()}
          initialValues={{ date: dayjs("2022-04-14 00:00:00") }}
        >
          <Form.Item
            className={styles["form-container__item"]}
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please input your date!" }]}
          >
            <DatePicker
              showTime
              disabledDate={(current) => {
                return (
                  current.isBefore(dayjs("2022-04-14 00:00:00")) ||
                  current.isAfter(dayjs("2022-07-13 00:00:00"))
                );
              }}
            />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please input your type!" }]}
          >
            <Select
              options={[
                {
                  label: "Withdraw",
                  value: "Withdraw",
                },
                {
                  label: "Deposit",
                  value: "Deposit",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Token"
            name="token"
            rules={[{ required: true, message: "Please input your token!" }]}
          >
            <Select
              options={prices.map(({ coin }) => ({
                label: coin,
                value: coin,
              }))}
            />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label={
              <>
                Value in USD&nbsp;
                <Tooltip
                  title={
                    "Enter the exact USD value for this transaction as per your Account Transaction History"
                  }
                >
                  <div className="self-center text-[#E8AF40]">⚠️</div>
                </Tooltip>
              </>
            }
            name="value"
            rules={[{ required: true, message: "Please input admin value!" }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item className={styles["form-container__item"]}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            {editAccountTx?.index !== null && (
              <Button type="primary" onClick={handleRemoveTxAccount}>
                Remove
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Step3;
