import axios from 'axios';
import EthCrypto from 'eth-crypto';
import { clientAPI, uploadFileAPI } from '../api/client';
import { opnxAPI } from '../api/opnx';

const hasOwn = {}.hasOwnProperty;

export const classNames: (...params: any) => string = (...params: any) => {
  const classes = [];

  for (let i = 0; i < params.length; i++) {
    const arg = params[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        const inner = classNames(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === "object") {
      if (arg.toString === Object.prototype.toString) {
        for (const key in arg) {
          if (hasOwn.call(arg, key) && arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(" ");
};

export const strimId = (id: string) => `${id.slice(0, 6)}...${id.slice(-6)}`;

export const survey_obj = (survey: string) => {
  var surveyString = survey.replace(/\\/g, "");
  // console.log(JSON.parse(surveyString));
  return JSON.parse(surveyString);
}

export const truncDigits=(inputNumber:number, digits:number)=> {
  const fact = 10 ** digits;
  return Math.floor(inputNumber * fact) / fact;
}

export const getFlexPriceAverange = async () => {
  let claim = await clientAPI("post", "/getPriceAverange", {
    symbol: "FLEX",
    slug: "flex",
  });
  return (claim.message || 0) / 100;
};

export const getOxPrice = async () => {
  try {
    let data = await opnxAPI("get", "/v3/markets?marketCode=OX-USDT");
      
    return +data.data[0].markPrice;
  } catch (error) {
    console.log("error =", error);
    return 0;
  }
};

export const calCulateFlexAmount = async (claimAmount: number) => {
  const LOC_FACTOR = 1.01;
  const flexPrice = await getOxPrice();
  const MULTIPLIER = 1 / (flexPrice * 300) + 0.15;

  const result = Math.pow((claimAmount * MULTIPLIER), LOC_FACTOR);
  return result;
}

export const encrypt = async(text: string) => {
  // console.log("public key =", publicKey);
    
  const encrypted = await EthCrypto.encryptWithPublicKey(
    process.env.REACT_APP_PUBLIC_KEY!, // publicKey
    text // message
  );
  
  // console.log("encrypted =", encrypted);
  return JSON.stringify(encrypted);
}
 
export const fixRounding = (value: number, precision: number) => {
  var power = Math.pow(10, precision || 0);
  return Math.round(value * power) / power;
}

export const uploadImage = async (options: any, setValue: any, field: string) => {
  const { onSuccess, onError, file } = options;

  const fmData = new FormData();
  fmData.append("file", file);
  let newFile = {...file}
  try {
    const res = await uploadFileAPI(fmData)
    if(res?.filename) {
      newFile.name= res?.fullPath
    }
    setValue(
      field,
      {
        file: newFile,
        fileList: newFile.status === "removed" ? [] : [newFile],
      }
    );
    onSuccess("Ok");
  } catch (err) {
    console.log("Eroor: ", err);
    const error = new Error("Some error");
    onError({ err });
  }
}

export function getWithExpiry(key: string) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

export function setWithExpiry(key: string, value: any, ttl: number) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}