import styles from "../index.module.scss";
import { Button, Col, Modal, Row, Tabs, TabsProps, Typography } from "antd";
import { useCallback, useContext, useMemo, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { ClaimContext } from "../../ClaimContextProvider";
import CryptoIcon from "../../../../components/CryptoIcon";
import { baseURL } from "../../../../api/client";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import clsx from "clsx";

const Balances: React.FC = () => {
  const {
    prices,
    celciusAccountBalance,
    celciusScheduleData,
    formData,
    celciusAccountTx,
  } = useContext(ClaimContext);

  const [preview, setPreview] = useState<string | null>(null);
  const [balanceView, setBalanceView] = useState<boolean | null>(null);
  const [selectedBalance, setSelectedBalance] = useState<any | null>(null);

  const [txHistoryView, setTxHistoryView] = useState<boolean | null>(null);
  const [selectedTx, setSelectedTx] = useState<any | null>(null);
  const [fileSuffix, setFileSuffix] = useState<any | null>(null);

  const baseBalanceData = useMemo(
    () =>
      !formData.claim_link ||
      !celciusScheduleData._id ||
      formData.is_soa_available === "No" ||
      formData.is_soa_amount_agreed === "No"
        ? celciusAccountBalance
        : celciusScheduleData,
    [celciusScheduleData, celciusAccountBalance, formData]
  );

  const getMatchedCoin = useCallback(
    (value: any) => {
      const icon =
        prices.find((price) => price.coin === value)?.coin?.toLowerCase() ??
        "btc";
      return icon;
    },
    [prices]
  );

  const getPrice = useCallback(
    (value: any) => {
      console.log("===>", {
        prices,
        value,
      });
      const price = prices.find((price) => price.coin === value)?.price ?? 0;
      return price;
    },
    [prices]
  );

  const renderTxTab = useCallback(
    (data: string) => {
      const baseData = celciusAccountTx[data as keyof typeof celciusAccountTx];

      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Date",
          dataIndex: "TxsDate",
          key: "TxsDate",
          render: (_, { date }, index) => (
            <Row key={index} className={""}>
              <Row align="middle" gutter={8}>
                <Col>
                  <Typography.Text>{date}</Typography.Text>
                </Col>
              </Row>
            </Row>
          ),
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          render: (_, { token }) => (
            <Row gutter={4}>
              <Col>
                {" "}
                <CryptoIcon name={getMatchedCoin(token)} />{" "}
              </Col>
              <Col>
                <Typography.Text>
                  {getMatchedCoin(token).toUpperCase()}
                </Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Value",
          key: "value",
          dataIndex: "value",
        },
      ];

      const suffix = data === "earn_account" ? "earn" : "custody";

      return (
        <div className={styles["tx-table"]}>
          <Table pagination={false} columns={columns} dataSource={baseData} />

          {formData?.[`transaction_${suffix}_type`] === "Upload File" &&
            formData?.[`celsius_excl_${suffix}`]?.fileList?.[0]?.name && (
              <Row className={styles["table-row"]}>
                <Col span={12}>
                  <Typography.Text strong>
                    {`${suffix.charAt(0).toUpperCase() + suffix.slice(1)}`} Tx
                    Excel File
                  </Typography.Text>
                </Col>
                <Row>
                  <Col span={12}>
                    <div className={""}>
                      {formData?.[`celsius_excl_${suffix}`]?.fileList?.[0]
                        ?.name ? (
                        <Button
                          className={"file-action text-[#03ABAB]"}
                          type="link"
                          onClick={() =>
                            setPreview(
                              baseURL +
                                "/files?name=" +
                                encodeURIComponent(formData?.[`celsius_excl_${suffix}`]
                                  ?.fileList?.[0]?.name) + "&token=" + localStorage.getItem('token')
                            )
                          }
                        >
                          View
                        </Button>
                      ) : (
                        // </a>
                        <Typography.Text>N/A</Typography.Text>
                      )}
                    </div>
                  </Col>
                </Row>
              </Row>
            )}

          {formData?.[`transaction_${suffix}_type`] !== "No Transaction" &&
            formData?.[`celsius_pdf_${suffix}`]?.fileList?.[0]?.name && (
              <Row className={styles["table-row"]}>
                <Col span={12}>
                  <Typography.Text strong>
                    {`${suffix.charAt(0).toUpperCase() + suffix.slice(1)}`} Tx
                    PDF File
                  </Typography.Text>
                </Col>
                <Row>
                  <Col span={12}>
                    <div className={""}>
                      {formData?.[`celsius_pdf_${suffix}`]?.fileList?.[0]
                        ?.name ? (
                        <Button
                          className={"file-action text-[#03ABAB]"}
                          type="link"
                          onClick={() =>
                            setPreview(
                              baseURL +
                                "/files?name=" +
                                encodeURIComponent(formData?.[`celsius_pdf_${suffix}`]
                                  ?.fileList?.[0]?.name) + "&token=" + localStorage.getItem('token')
                            )
                          }
                        >
                          View
                        </Button>
                      ) : (
                        // </a>
                        <Typography.Text>N/A</Typography.Text>
                      )}
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
        </div>
      );
    },
    [celciusAccountTx, formData, getMatchedCoin]
  );

  const renderBalanceTab = useCallback(
    (data: string) => {
      const baseData = baseBalanceData[data as keyof typeof baseBalanceData];

      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          render: (_, { price, token }) => (
            <Row gutter={4}>
              <Col>
                {" "}
                <CryptoIcon name={getMatchedCoin(token)} />{" "}
              </Col>
              <Col>
                <Typography.Text>
                  {getMatchedCoin(token).toUpperCase()}
                </Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          render: (_, item) => (
            <Typography.Text>${getPrice(item.token)}</Typography.Text>
          ),
        },
        {
          title: "Quantity",
          dataIndex: "value",
          key: "value",
        },
        {
          title: "Value",
          key: "item_value",
          dataIndex: "item_value",
          render: (_, item) => (
            <Typography.Text>
              ${item["value"] * +getPrice(item.token)}
            </Typography.Text>
          ),
        },
      ];

      return (
        <Table columns={columns} dataSource={baseData} pagination={false} />
      );
    },
    [baseBalanceData, getMatchedCoin, getPrice]
  );

  const handleBalanceTabForMobile = (data: any) => {
    const baseData = baseBalanceData[data as keyof typeof baseBalanceData];
    setBalanceView(true);
    setSelectedBalance(baseData);
  };

  const handleTxHistoryForMobile = (data: any) => {
    const baseData = celciusAccountTx[data as keyof typeof celciusAccountTx];
    const suffix = data === "earn_account" ? "earn" : "custody";
    setSelectedTx(baseData);
    setFileSuffix(suffix);
    setTxHistoryView(true);
  };

  const balanceItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        value: "earn_account",
        children: renderBalanceTab("earn_account"),
      },
      {
        key: "2",
        label: `Custody Account`,
        value: "custody_account",
        children: renderBalanceTab("custody_account"),
      },
      {
        key: "3",
        label: `Withheld Account`,
        value: "withheld_account",
        children: renderBalanceTab("withheld_account"),
      },
      {
        key: "4",
        label: `Borrow Account`,
        value: "collateral_on_loan_receivable",
        children: renderBalanceTab("collateral_on_loan_receivable"),
      },
    ],
    [renderBalanceTab]
  );

  const mobileBalanceItems = [
    {
      label: `Earn Account`,
      value: "earn_account",
    },
    {
      label: `Custody Account`,
      value: "custody_account",
    },
    {
      label: `Withheld Account`,
      value: "withheld_account",
    },
    {
      label: `Borrow Account`,
      value: "collateral_on_loan_receivable",
    },
  ];

  const txHistoryItems = [
    {
      label: `Earn Account`,
      value: "earn_account",
    },
    {
      label: `Custody Account`,
      value: "custody_account",
    },
  ];

  const txItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTxTab("earn_account"),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTxTab("custody_account"),
      },
    ],
    [renderTxTab]
  );

  return (
    <div className={styles["account-balances"]}>
      <div className={clsx(styles.block, `hidden sm:!block`)}>
        <Typography.Text className={styles.title}>Balances</Typography.Text>
        <Tabs defaultActiveKey="1" items={balanceItems} />
      </div>

      <div className="flex flex-col sm:!hidden">
        <div className="text-[#111111] text-lg font-semibold">Balances</div>

        {mobileBalanceItems.map((el) => (
          <div className="flex justify-between py-2 border-b border-[#EDEDED]">
            <div className="text-[#111111] text-sm font-semibold">
              {el.label}
            </div>
            <MdOutlineKeyboardArrowRight
              className="text-[#03ABAB] text-xl cursor-pointer"
              onClick={() => handleBalanceTabForMobile(el.value)}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-col mt-5 mb-10 sm:!hidden">
        <div className="text-[#111111] text-lg font-semibold">Tx History</div>

        {txHistoryItems.map((el) => (
          <div className="flex justify-between py-2 border-b border-[#EDEDED]">
            <div className="text-[#111111] text-sm font-semibold">
              {el.label}
            </div>
            <MdOutlineKeyboardArrowRight
              className="text-[#03ABAB] text-xl cursor-pointer"
              onClick={() => handleTxHistoryForMobile(el.value)}
            />
          </div>
        ))}
      </div>

      <div className={clsx(styles.block, `hidden sm:block`)}>
        <Typography.Text className={styles.title}>Tx History</Typography.Text>
        <Tabs defaultActiveKey="1" items={txItems} />
      </div>

      {formData?.["celsius_statement"]?.fileList?.[0]?.name && (
        <div className={styles.block}>
          <Typography.Text className={styles.title}>
            Account Statement
          </Typography.Text>
          <div className="flex items-center align-middle justify-between p-[20px] border border-[#EDEDED] rounded-lg">
            <Typography.Text className="font-bold text-sm">
              Account Statement PDF
            </Typography.Text>
            <Button
              className={"file-action text-[#03ABAB]"}
              type="link"
              onClick={() =>
                setPreview(baseURL +"/files?name=" +
                encodeURIComponent(formData?.["celsius_statement"]?.fileList?.[0]?.name) + "&token=" + localStorage.getItem('token'))
              }
            >
              View
            </Button>
          </div>
        </div>
      )}

      <Modal
        open={!!preview}
        onCancel={() => setPreview(null)}
        centered
        footer={false}
        width={"80vw"}
        bodyStyle={{ height: "80vh" }}
      >
        <Row style={{ height: "100%" }}>
          {preview && (
            <DocViewer
              documents={[{ uri: preview }]}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </Row>
      </Modal>
      <Modal
        footer={null}
        title="Balances"
        open={!!balanceView}
        onCancel={() => setBalanceView(null)}
      >
        <div className="flex flex-col gap-5">
          {selectedBalance &&
            selectedBalance.map((item: any) => {
              return (
                <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Token
                    </span>
                    <div className="flex gap-2">
                      <CryptoIcon name={getMatchedCoin(item?.token)} />
                      <span className="font-normal text-[#666666] text-sm">
                        {getMatchedCoin(item?.token).toUpperCase()}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Price as of July 13, 2022
                    </span>
                    <span className="font-normal text-[#666666] text-sm">
                      ${getPrice(item?.token)}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Quantity
                    </span>
                    <span className="font-normal text-[#666666] text-sm">
                      {item?.value}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Value
                    </span>
                    <span className="font-normal text-[#666666] text-sm">
                      <Typography.Text>
                        ${item["value"] * +getPrice(item?.token)}
                      </Typography.Text>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>

      <Modal
        footer={null}
        title="Tx History"
        open={!!txHistoryView}
        onCancel={() => setTxHistoryView(null)}
      >
        <div className="flex flex-col gap-5">
          {selectedTx &&
            selectedTx.map((item: any) => {
              return (
                <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Date
                    </span>

                    <span className="font-normal text-[#666666] text-sm">
                      {item?.date}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Type
                    </span>
                    <span className="font-normal text-[#666666] text-sm">
                      {item?.type}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Token
                    </span>
                    <div className="flex gap-2">
                      <CryptoIcon name={getMatchedCoin(item.token)} />
                      <span className="font-normal text-[#666666] text-sm">
                        {getMatchedCoin(item.token).toUpperCase()}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <span className="font-semibold text-[#111111] text-sm">
                      Value
                    </span>
                    <span className="font-normal text-[#666666] text-sm">
                      <Typography.Text>{item.value}</Typography.Text>
                    </span>
                  </div>
                </div>
              );
            })}

          {formData?.[`celsius_excl_${fileSuffix}`]?.file && (
            <div className="flex flex-col">
              <Typography.Text strong>
                {`${fileSuffix.charAt(0).toUpperCase() + fileSuffix.slice(1)}`}{" "}
                Tx Excel File
              </Typography.Text>

              <div className={""}>
                {formData?.[`celsius_excl_${fileSuffix}`]?.file ? (
                  <button
                    className={
                      "border border-#E2E2E2 rounded-md text-[#03ABAB] py-2 w-full text-center"
                    }
                    onClick={() =>
                      setPreview(
                        baseURL +
                          "/files?name=" +
                          encodeURIComponent(formData?.[`celsius_excl_${fileSuffix}`]?.file.name) + "&token=" + localStorage.getItem('token')
                      )
                    }
                  >
                    <div className="flex gap-1 text-[#03ABAB]">
                      <div className="text-[14px] font-semibold">View</div>
                      <AiFillEye className="text-xl" />
                    </div>
                  </button>
                ) : (
                  // </a>
                  <Typography.Text>N/A</Typography.Text>
                )}
              </div>
            </div>
          )}

          {formData?.[`celsius_pdf_${fileSuffix}`]?.file && (
            <div className="flex flex-col">
              <Typography.Text strong>
                {`${fileSuffix.charAt(0).toUpperCase() + fileSuffix.slice(1)}`}{" "}
                Tx PDF File
              </Typography.Text>

              <div className={""}>
                {formData?.[`celsius_pdf_${fileSuffix}`]?.file ? (
                  <button
                    className={
                      "border border-#E2E2E2 rounded-md text-[#03ABAB] py-2 w-full text-center"
                    }
                    onClick={() =>
                      setPreview(
                        baseURL +
                          "/files?name=" +
                          encodeURIComponent(formData?.[`celsius_pdf_${fileSuffix}`]?.file.name) + "&token=" + localStorage.getItem('token')
                      )
                    }
                  >
                    <div className="flex gap-1 text-[#03ABAB]">
                      <div className="text-[14px] font-semibold">View</div>
                      <AiFillEye className="text-xl" />
                    </div>
                  </button>
                ) : (
                  // </a>
                  <Typography.Text>N/A</Typography.Text>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Balances;
