import React, { useCallback, useEffect, useMemo, useState } from "react";
import { baseURL, clientAPI } from "../../api/client";
import { Form, FormInstance, message } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { CLAIM_TYPE_FTX } from "../../common/constants";

const CLAIM_TYPE = CLAIM_TYPE_FTX

export interface ScheduleFLineAccount
  extends Array<{
    _id?: string;
    token: string;
    value: string;
  }> {}

export interface ScheduleFLine {
  _id: null | string;
  schedule_f_line: null | string;
  creditors_name: null | string;
  address: null | string;
  indicate: null | string;
  is_claim_to_offset: boolean;
  earn_account: ScheduleFLineAccount;
  custody_account: ScheduleFLineAccount;
  withheld_account: ScheduleFLineAccount;
  collateral_on_loan_receivable: ScheduleFLineAccount;
}
export interface CelciusAccountBalance {
  earn_account: ScheduleFLineAccount;
  custody_account: ScheduleFLineAccount;
  withheld_account: ScheduleFLineAccount;
  collateral_on_loan_receivable: ScheduleFLineAccount;
}

export interface CelciusTx
  extends Array<{
    date: string;
    type: "Withdraw" | "Deposit";
    token: string;
    value: number;
  }> {}

export interface CelciusAccountTx {
  earn_account: CelciusTx;
  custody_account: CelciusTx;
}

interface FTXClaimContextProviderProps {
  children?: React.ReactElement;
}
interface ClaimContextData {
  currentStep: number;
  claimId?: string;
  submitted: boolean;
  progress: {
    title: string;
    content?: any
  }[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  userData: Record<string, any> | undefined;
  formData: Record<string, any>;
  handleNext: () => void;
  useYupValidationResolver: (schema: any) => any;
  handleBack: () => void;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  countries: Record<string, any>[];
  messageContextHolder: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  FTXScheduleData: any;
  prices: {
    coin: string;
    price: string;
  }[];
  celciusAccountBalance: CelciusAccountBalance;
  setCelciusAccountBalance: React.Dispatch<
    React.SetStateAction<CelciusAccountBalance>
  >;
  getFTXScheduleData: (line: string) => Promise<void>;
  celciusAccountTx: CelciusAccountTx;
  setCelciusAccountTx: React.Dispatch<React.SetStateAction<CelciusAccountTx>>;
  claimDataLoading: boolean;
  handleUpdateForm: (data: Record<string, any>) => void;
  handleSubmit: () => Promise<void>;
  isExceedLimit: boolean;
  resetForm: () => void;
  form: FormInstance;
  messageApi: any;
  submitLoading: boolean;
  accountBalance: number;
  isOpen: any;
  setIsOpen: any;
}

export const DEFAULT_SCHEDULE: ScheduleFLine = {
  _id: null,
  schedule_f_line: null,
  creditors_name: null,
  address: null,
  indicate: null,
  is_claim_to_offset: false,
  earn_account: [],
  custody_account: [],
  withheld_account: [],
  collateral_on_loan_receivable: [],
};

const DEFAULT_ACCOUNT_BALANCE: CelciusAccountBalance = {
  earn_account: [],
  custody_account: [],
  withheld_account: [],
  collateral_on_loan_receivable: [],
};

const DEFAULT_ACCOUNT_TX: CelciusAccountTx = {
  earn_account: [],
  custody_account: [],
};

export const FTXClaimContext = React.createContext({} as ClaimContextData);

const FTXClaimContextProvider = ({ children }: FTXClaimContextProviderProps) => {
  const [submitted, setIsSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, any>>({
    celsius_relationship: [],
    transaction_earn_type: "Manual",
    transaction_custody_type: "Manual",
  });

  const [claimDataLoading, setClaimDataLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [claimInfo, setClaimInfo] = useState<Record<string, any>>();
  const [userData, setUserData] = useState<Record<string, any>>();
  const [prices, setPrices] = useState<{ coin: string; price: string }[]>([]);
  const [countries, setCountries] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<any>('');

  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("accountId");
  const [claimId, setClaimId] = useState("");
  const [isExceedLimit, setIsExceedLimit] = useState(false);
  const [form] = Form.useForm();

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data: Record<string, any>) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors?.inner?.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const handleUpdateForm = useCallback(
    (data: Record<string, any>) => {
      setFormData({
        ...formData,
        ...data,
      });
    },
    [formData]
  );

  const handleNext = () => setCurrentStep((prev) => Math.min(++prev, 4));

  const handleBack = () => setCurrentStep((prev) => Math.max(--prev, 0));

  const resetForm = () => {
    window.location.reload()
    return 
    // setIsSubmitted(false);
    // setClaimId("");
    // setCurrentStep(0);
    // setFTXScheduleData(DEFAULT_SCHEDULE);
    // setCelciusAccountBalance(DEFAULT_ACCOUNT_BALANCE)
    // setCelciusAccountTx(DEFAULT_ACCOUNT_TX)
    // setFormData({
    //   celsius_relationship: [],
    //   transaction_earn_type: "Manual",
    //   transaction_custody_type: "Manual",
    // });
  };
  // unique 6 char
  function makeUnique(base: string) {
    var now = new Date().getTime().toString();
    var random = Math.floor(Math.random() * 1000)
    return base + now.slice(-6) + random;
  }

  const [FTXScheduleData, setFTXScheduleData] =
  useState<any>();

  const accountBalance = useMemo(() => {
    const accountBalance = FTXScheduleData?.tokenData.reduce((previousValue: number, currentValue: any) => {
      return (
        previousValue +
        Number(currentValue.value) *
          Number(
            prices.find((price) => price.coin === currentValue.tokenName)
              ?.price ?? null
          )
      );
    }, 0)
    return accountBalance
  }, [FTXScheduleData, prices])

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true)
    if(submitLoading || claimDataLoading) return
    try {
      setSubmitLoading(true)
      const bodyData = {
        user_type:
        userData?.accountType === "INDIVIDUAL" ? "Individual" : "Corporate",
        legal_name: userData?.legalName ?? "",
        attention: userData?.attention ?? "",
        email: userData?.email,
        phone_number: userData?.phone ?? "",
        is_usa: userData?.usEntity ? "Yes" : "No",
        is_kyc: userData?.verifiedKyc ? "Yes" : "No",
        ftx_claim_owner: values.ftx_claim_owner,
        claim_f_line: values.claim_f_line,
        is_soa_details_agreed: values.is_soa_details_agreed,
        schedule_data: FTXScheduleData,
        ftx_claim_data: FTXScheduleData?.tokenData.map((item: any) => {
          return {
            "Claim Value":
              Number(item.value) *
              Number(
                prices.find((price) => price.coin === item.tokenName)?.price ?? null
              ),
            Coin:
              prices.find((price) => price.coin === item.tokenName)?.price ?? "",
            price:
              prices.find((price) => price.coin === item.tokenName)?.price ?? "",
            Quantity: Number(item.value),
            tokenName: item.tokenName,
          };
        }),
        ftx_account_balance: accountBalance,
        ftx_email: values.ftx_email,
        opnx_data: {
          accountId: localStorage.getItem("accountId"),
          applicationId: localStorage.getItem("applicationId"),
          claimType: CLAIM_TYPE,
          countryOfResidency: userData?.countryOfResidency,
          addressDetail: `${userData?.address?.floorNumber || ''} ${userData?.address?.street|| ''}, ${userData?.address?.city|| ''}, ${userData?.address?.postalCode|| ''}, ${userData?.address?.country|| ''}`,
          address: userData?.address
        },
      };

      const claim_id = makeUnique('ftx_');
      
      setClaimId(claim_id);

      await clientAPI("post", "/submitFtxClaim", {
        claim_id,
        survey: JSON.stringify(bodyData),
        claim_type: CLAIM_TYPE,
      });
      getExceedLimit();
      setIsSubmitted(true);
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  };

 
  const [celciusAccountBalance, setCelciusAccountBalance] =
    useState<CelciusAccountBalance>(DEFAULT_ACCOUNT_BALANCE);
  const [celciusAccountTx, setCelciusAccountTx] =
    useState<CelciusAccountTx>(DEFAULT_ACCOUNT_TX);
  const [messageApi, messageContextHolder] = message.useMessage();
  const progress = [
    {
      title: "Step 01",
      content: <Step1 />
    },
    {
      title: "Step 02",
      content: <Step2 />
    },
    {
      title: "Step 03",
      content: <Step3 />
    },
  ];

  const getKycInfo = async () => {
    try {
      const result = await clientAPI("post", "/kycInfo", {
        accountId,
        loginToken: token,
      });

      if (result.status !== "FAILED") {
        setUserData(result.message.data);
      } else {
        // window.open("/#/", "_self");
      }
    } catch (error) {}
  };

  const getClaimInfo = async () => {
    try {
      const result = await clientAPI("post", "/claimInfo", {
        accountId,
        loginToken: token,
      });

      if (result.status !== "FAILED") {
        setClaimInfo(result.message.data);
      } else {
        // window.open("/#/", "_self");
      }
    } catch (error) {}
  };

  const getExceedLimit = async (claimType=CLAIM_TYPE) => {
    try {
      const accountId = localStorage.getItem("accountId");
      const token = localStorage.getItem("token");

      const responses = await clientAPI("post", "/verifyExceedLimit", {
        accountId: accountId,
        loginToken: token,
        claim_type: claimType,
      });
      if (responses.status !== "FAILED") {
        setIsExceedLimit(Boolean(responses.message));
      }
    } catch (error) {}
  };

  const getFTXScheduleData = async (line: string) => {
    if (!line) {
      setFTXScheduleData(null);
      return;
    }
    setClaimDataLoading(true);
    try {
      // messageApi.loading({
      //   key: "get_schedule_f_line_loading",
      //   content: "Waiting for data......",
      // });
      const result = await clientAPI("post", "/ftxSchedule/" + line);
      console.log(result, 'resultresult');
      
      if (!result.message) {
        throw new Error();
      }
      if (result.status !== "FAILED" && result.message) {
        messageApi.success({
          key: "get_schedule_f_line_success",
          content: "FTX Data Loaded",
          duration: 2,
        });
        setFTXScheduleData(result.message);
      } else {
        setIsOpen(true)
        messageApi.destroy("get_schedule_f_line_loading");
        messageApi.error({
          key: "get_schedule_f_line_error",
          content: "Data not found in Schedule of Assets",
          duration: 2,
        });
      }

      setClaimDataLoading(false);
    } catch (error) {
      setIsOpen(true)
      setFTXScheduleData(null);
      setClaimDataLoading(false);
      messageApi.destroy("get_schedule_f_line_loading");
      messageApi.error({
        key: "get_schedule_f_line_error",
        content: "Data not found in Schedule of Assets",
        duration: 2,
      });
    }
  };

  useEffect(() => {
    (async () => {
      fetch("ftx-coin-prices.json")
        .then((res) => res.json())
        .then((data) => {
          setPrices(data);
        });
      fetch("countries.json")
        .then((res) => res.json())
        .then((data) => {
          setCountries(data);
        });

      getExceedLimit();
      getKycInfo();
      getClaimInfo();
    })();
  }, []);

  return (
    <FTXClaimContext.Provider
      value={{
        progress,
        currentStep,
        setCurrentStep,
        userData,
        formData,
        setFormData,
        handleNext,
        countries,
        messageContextHolder,
        useYupValidationResolver,
        FTXScheduleData,
        prices,
        celciusAccountBalance,
        handleBack,
        setCelciusAccountBalance,
        getFTXScheduleData,
        celciusAccountTx,
        setCelciusAccountTx,
        claimDataLoading,
        handleUpdateForm,
        handleSubmit,
        claimId,
        submitted,
        isExceedLimit,
        resetForm,
        form,
        messageApi,
        submitLoading,
        accountBalance,
        isOpen, 
        setIsOpen
      }}
    >
      {children}
    </FTXClaimContext.Provider>
  );
};

export default FTXClaimContextProvider;
