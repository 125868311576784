import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Select,
  Input,
  Typography,
} from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import USER_ICON from "../../../statics/images/user.svg";
import FILE_ICON from "../../../statics/images/fi_file-text.svg";
import PHONE_ICON from "../../../statics/images/fi_phone.svg";
import { ClaimContext } from "../ClaimContextProvider";
import styles from "./index.module.scss";
import clsx from "clsx";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { step_1, step_4 } from "./schema";
import dayjs from "dayjs";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { baseURL } from "../../../api/client";
import { uploadImage } from "../../../common/utils";

const Step5 = () => {
  const {
    userData,
    countries,
    handleNext,
    handleBack,
    useYupValidationResolver,
    formData,
    handleUpdateForm,
  } = useContext(ClaimContext);

  type FormData = yup.InferType<typeof step_4>;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: useYupValidationResolver(step_4),
    defaultValues: {
      is_poc_filed: formData.is_poc_filed,
      is_poc_general_bar: formData.is_poc_general_bar,
      poc_file_number: formData.poc_file_number,
      is_poc_registered: formData.is_poc_registered,
      poc_link: formData.poc_link,
      name_appear_poc: formData.name_appear_poc
    },
  });

  const values = useWatch({ control });

  const handleNextStep = useCallback(() => {
    handleUpdateForm(values);
    handleNext();
  }, [handleNext, handleUpdateForm, values]);

  const handleBackStep = useCallback(() => {
    handleUpdateForm(values);
    handleBack();
  }, [handleBack, handleUpdateForm, values]);

  const currentFileList = useMemo(
    () =>
      values.poc_link
        ? (values.poc_link as Record<string, any>).fileList || []
        : [],
    [values]
  );

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = ({ file, fileList, event }: any) => {
    setDefaultFileList(fileList);
  };

  return (
    <Form onFinish={handleSubmit(handleNextStep)} layout="vertical">
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl">
          Stretto/Celsius Details
        </Typography.Text>

        <Form.Item required label={`Have you filed a Proof of Claim?`}>
          <Controller
            name="is_poc_filed"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                status={errors.is_poc_filed && "error"}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                placeholder="Select"
              />
            )}
          />

          <Typography.Text type="danger">
            {errors.is_poc_filed?.message?.toString()}
          </Typography.Text>
        </Form.Item>

        {values.is_poc_filed === "Yes" && (
          <>
            {" "}
            <Form.Item
              required
              label={`Date/Time (Eastern Time) on which you filed the Proof of Claim`}
            >
              <Controller
                name="is_poc_general_bar"
                control={control}
                render={({ field: { name, value, onBlur, onChange } }) => (
                  <DatePicker
                    // {...field}
                    name={name}
                    value={value !== undefined ? dayjs(value) : value}
                    onBlur={onBlur}
                    onChange={onChange}
                    status={errors.is_poc_general_bar && "error"}
                    showTime
                    // disabledDate={(current) => {
                    //   return (
                    //     current.isBefore(dayjs("2022-04-14 00:00:00")) ||
                    //     current.isAfter(dayjs("2022-07-13 00:00:00"))
                    //   );
                    // }}
                  />
                )}
              />

              <Typography.Text type="danger">
                {errors.is_poc_general_bar?.message?.toString()}
              </Typography.Text>
            </Form.Item>
            <Form.Item required label="Enter your filed Proof of Claim number">
              <Controller
                name="poc_file_number"
                control={control}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    status={errors.poc_file_number && "error"}
                  />
                )}
              />

              <Typography.Text type="danger">
                {errors.poc_file_number?.message?.toString()}
              </Typography.Text>
            </Form.Item>
            <Form.Item required label="Enter your name as it appears on your Proof of Claim">
              <Controller
                name="name_appear_poc"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input {...field} status={errors.name_appear_poc && "error"} />
                )}
              />

              <Typography.Text type="danger">
                {errors.name_appear_poc?.message?.toString()}
              </Typography.Text>
            </Form.Item>
            <Form.Item
              required
              label={`Are you aware of any objections filed or due to be filed against your Proof of Claim?`}
            >
              <Controller
                name="is_poc_registered"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    status={errors.is_poc_registered && "error"}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    placeholder="Select"
                  />
                )}
              />

              <Typography.Text type="danger">
                {errors.is_poc_registered?.message?.toString()}
              </Typography.Text>
            </Form.Item>
            <Form.Item label="Upload a PDF of your Proof of Claim" required>
             
                  <Dragger
                    listType="picture"
                    accept=".pdf"
                    customRequest={(options) => uploadImage(options, setValue, 'poc_link')}
                    name="file"
                    onChange={handleOnChange}
                    defaultFileList={
                      defaultFileList?.length ? defaultFileList: formData.poc_link?.fileList
                    }
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drop your file here, or browse
                    </p>
                    <p className="ant-upload-hint">Support PDF</p>
                  </Dragger>
              <Typography.Text type="danger">
                {errors.poc_link?.message?.toString()}
              </Typography.Text>
            </Form.Item>
          </>
        )}
      </div>
      <div className={styles.navigation}>
        <Button onClick={handleBackStep}>Back</Button>
        <Button htmlType="submit">Next</Button>
      </div>
    </Form>
  );
};
export default Step5;
