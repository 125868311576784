import React, { useCallback, useEffect, useState } from "react";
import { baseURL, clientAPI } from "../../api/client";
import { message } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { CLAIM_TYPE } from "../../common/constants";

export interface ScheduleFLineAccount
  extends Array<{
    _id?: string;
    token: string;
    value: string;
  }> {}

export interface ScheduleFLine {
  _id: null | string;
  schedule_f_line: null | string;
  creditors_name: null | string;
  address: null | string;
  indicate: null | string;
  is_claim_to_offset: boolean;
  earn_account: ScheduleFLineAccount;
  custody_account: ScheduleFLineAccount;
  withheld_account: ScheduleFLineAccount;
  collateral_on_loan_receivable: ScheduleFLineAccount;
}
export interface CelciusAccountBalance {
  earn_account: ScheduleFLineAccount;
  custody_account: ScheduleFLineAccount;
  withheld_account: ScheduleFLineAccount;
  collateral_on_loan_receivable: ScheduleFLineAccount;
}

export interface CelciusTx
  extends Array<{
    date: string;
    type: "Withdraw" | "Deposit";
    token: string;
    value: number;
  }> {}

export interface CelciusAccountTx {
  earn_account: CelciusTx;
  custody_account: CelciusTx;
}

interface ClaimContextProviderProps {
  children?: React.ReactElement;
}
interface ClaimContextData {
  currentStep: number;
  claimId?: string;
  submitted: boolean;
  progress: {
    title: string;
  }[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  userData: Record<string, any> | undefined;
  formData: Record<string, any>;
  handleNext: () => void;
  useYupValidationResolver: (schema: any) => any;
  handleBack: () => void;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  countries: Record<string, any>[];
  messageContextHolder: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  celciusScheduleData: ScheduleFLine;
  prices: {
    coin: string;
    price: string;
  }[];
  celciusAccountBalance: CelciusAccountBalance;
  setCelciusAccountBalance: React.Dispatch<
    React.SetStateAction<CelciusAccountBalance>
  >;
  getCelciusScheduleData: (line: string) => Promise<void>;
  celciusAccountTx: CelciusAccountTx;
  setCelciusAccountTx: React.Dispatch<React.SetStateAction<CelciusAccountTx>>;
  claimDataLoading: boolean;
  handleUpdateForm: (data: Record<string, any>) => void;
  handleSubmit: () => Promise<void>;
  isExceedLimit: boolean;
  resetForm: () => void;
}

export const DEFAULT_SCHEDULE: ScheduleFLine = {
  _id: null,
  schedule_f_line: null,
  creditors_name: null,
  address: null,
  indicate: null,
  is_claim_to_offset: false,
  earn_account: [],
  custody_account: [],
  withheld_account: [],
  collateral_on_loan_receivable: [],
};

const DEFAULT_ACCOUNT_BALANCE: CelciusAccountBalance = {
  earn_account: [],
  custody_account: [],
  withheld_account: [],
  collateral_on_loan_receivable: [],
};

const DEFAULT_ACCOUNT_TX: CelciusAccountTx = {
  earn_account: [],
  custody_account: [],
};

export const ClaimContext = React.createContext({} as ClaimContextData);

const ClaimContextProvider = ({ children }: ClaimContextProviderProps) => {
  const [submitted, setIsSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, any>>({
    celsius_relationship: [],
    transaction_earn_type: "Manual",
    transaction_custody_type: "Manual",
  });

  const [claimDataLoading, setClaimDataLoading] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [claimInfo, setClaimInfo] = useState<Record<string, any>>();
  const [userData, setUserData] = useState<Record<string, any>>();
  const [prices, setPrices] = useState<{ coin: string; price: string }[]>([]);
  const [countries, setCountries] = useState<any>([]);

  const token = localStorage.getItem("token");
  const accountId = localStorage.getItem("accountId");
  const [claimId, setClaimId] = useState("");
  const [isExceedLimit, setIsExceedLimit] = useState(false);

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data: Record<string, any>) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors?.inner?.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const handleUpdateForm = useCallback(
    (data: Record<string, any>) => {
      setFormData({
        ...formData,
        ...data,
      });
    },
    [formData]
  );

  const handleNext = () => setCurrentStep((prev) => Math.min(++prev, 4));

  const handleBack = () => setCurrentStep((prev) => Math.max(--prev, 0));

  const resetForm = () => {
    window.location.reload()
    return 
    // setIsSubmitted(false);
    // setClaimId("");
    // setCurrentStep(0);
    // setCelciusScheduleData(DEFAULT_SCHEDULE);
    // setCelciusAccountBalance(DEFAULT_ACCOUNT_BALANCE)
    // setCelciusAccountTx(DEFAULT_ACCOUNT_TX)
    // setFormData({
    //   celsius_relationship: [],
    //   transaction_earn_type: "Manual",
    //   transaction_custody_type: "Manual",
    // });
  };

  const handleSubmit = async () => {
    try {
      const totalCelsiusEarnBalances = (
        formData?.is_soa_available === "No" ||
        (formData?.is_soa_available === "Yes" &&
          formData?.is_soa_amount_agreed === "No")
          ? celciusAccountBalance?.earn_account ?? []
          : celciusScheduleData?.earn_account ?? []
      ).reduce((previousValue, currentValue: any) => {
        return (
          previousValue +
          Number(currentValue.value) *
            Number(
              prices.find((price) => price.coin === currentValue.token)
                ?.price ?? null
            )
        );
      }, 0);

      const totalCelsiusCustodyBalances = (
        formData?.is_soa_available === "No" ||
        (formData?.is_soa_available === "Yes" &&
          formData?.is_soa_amount_agreed === "No")
          ? celciusAccountBalance?.custody_account ?? []
          : celciusScheduleData?.custody_account ?? []
      ).reduce((previousValue, currentValue: any) => {
        return (
          previousValue +
          Number(currentValue.value) *
            Number(
              prices.find((price) => price.coin === currentValue.token)
                ?.price ?? null
            )
        );
      }, 0);

      const totalCelsiusWitheldBalances = (
        formData?.is_soa_available === "No" ||
        (formData?.is_soa_available === "Yes" &&
          formData?.is_soa_amount_agreed === "No")
          ? celciusAccountBalance?.withheld_account ?? []
          : celciusScheduleData?.withheld_account ?? []
      ).reduce((previousValue, currentValue: any) => {
        return (
          previousValue +
          Number(currentValue.value) *
            Number(
              prices.find((price) => price.coin === currentValue.token)
                ?.price ?? null
            )
        );
      }, 0);

      const totalCelsiusBorrowBalances = (
        formData?.is_soa_available === "No" ||
        (formData?.is_soa_available === "Yes" &&
          formData?.is_soa_amount_agreed === "No")
          ? celciusAccountBalance?.collateral_on_loan_receivable ?? []
          : celciusScheduleData?.collateral_on_loan_receivable ?? []
      ).reduce((previousValue, currentValue: any) => {
        return (
          previousValue +
          Number(currentValue.value) *
            Number(
              prices.find((price) => price.coin === currentValue.token)
                ?.price ?? null
            )
        );
      }, 0);

      const earnPreferenceRisk = Math.max(
        celciusAccountTx.earn_account
          .filter(({ date, token, type, value }) => {
            return !!date && !!token && !!type && !!value;
          })
          .sort(
            (prev, next) => moment(prev.date).unix() - moment(next.date).unix()
          )
          .reduce((previousValue, { type, value }) => {
            return type === "Withdraw"
              ? previousValue + Number(value)
              : Math.max(previousValue - Number(value), 0);
          }, 0),
        0
      );

      const custodyPreferenceRisk = Math.max(
        celciusAccountTx.custody_account
          .filter(({ date, token, type, value }) => {
            return !!date && !!token && !!type && !!value;
          })
          .sort(
            (prev, next) => moment(prev.date).unix() - moment(next.date).unix()
          )
          .reduce((previousValue, { type, value }) => {
            return type === "Withdraw"
              ? previousValue + Number(value)
              : Math.max(previousValue - Number(value), 0);
          }, 0),
        0
      );

      const accountPreferenceRisk = custodyPreferenceRisk + earnPreferenceRisk;

      const bodyData = {
        user_type:
        userData?.accountType === "INDIVIDUAL" ? "Individual" : "Corporate",
        legal_name: userData?.legalName ?? "",
        attention: userData?.attention ?? "",
        phone_number: userData?.phone ?? "",
        is_usa: userData?.usEntity ? "Yes" : "No",
        is_kyc: userData?.verifiedKyc ? "Yes" : "No",
        contact_email: formData?.contact_email,
        country: formData?.country,
        is_soa_available: formData?.is_soa_available,
        creditors_name: celciusScheduleData?.creditors_name,
        ...(formData?.is_soa_available === "Yes"
          ? {
              claim_link: formData?.claim_link,
              is_soa_amount_agreed: formData?.is_soa_amount_agreed,
            }
          : {}),
        celsius_statement: formData?.celsius_statement?.fileList?.map(
          (fileItem: any) => {
            return {
              ...fileItem,
              content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
            };
          }
        ),
        celsius_email: formData?.celsius_email,
        celsius_address: formData?.celsius_address,
        celsius_relationship: formData?.celsius_relationship,
        celsius_earn_balances: (formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
          ? celciusAccountBalance?.earn_account ?? []
          : celciusScheduleData?.earn_account ?? []
        ).map((item) => {
          return {
            "Claim Value":
              Number(item.value) *
              Number(
                prices.find((price) => price.coin === item.token)?.price ?? null
              ),
            Coin:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            price:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            Quantity: Number(item.value),

            token: item.token,
          };
        }),
        celsius_custody_balances: (formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
          ? celciusAccountBalance?.custody_account ?? []
          : celciusScheduleData?.custody_account ?? []
        ).map((item) => {
          return {
            "Claim Value":
              Number(item.value) *
              Number(
                prices.find((price) => price.coin === item.token)?.price ?? null
              ),
            Coin:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            price:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            Quantity: Number(item.value),

            token: item.token,
          };
        }),
        celsius_witheld_balances: (formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
          ? celciusAccountBalance?.withheld_account ?? []
          : celciusScheduleData?.withheld_account ?? []
        ).map((item) => {
          return {
            "Claim Value":
              Number(item.value) *
              Number(
                prices.find((price) => price.coin === item.token)?.price ?? null
              ),
            Coin:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            price:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            Quantity: Number(item.value),

            token: item.token,
          };
        }),
        celsius_borrow_balances: (formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
          ? celciusAccountBalance?.collateral_on_loan_receivable ?? []
          : celciusScheduleData?.collateral_on_loan_receivable ?? []
        ).map((item) => {
          return {
            "Claim Value":
              Number(item.value) *
              Number(
                prices.find((price) => price.coin === item.token)?.price ?? null
              ),
            Coin:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            price:
              prices.find((price) => price.coin === item.token)?.price ?? "",
            Quantity: Number(item.value),

            token: item.token,
          };
        }),
        celsius_tx_earn: celciusAccountTx.earn_account.map(
          ({ date, token, type, value }) => {
            return {
              TxsDate: date,
              Type: type,
              Value: value,
              Coin: Number(
                prices.find((price) => price.coin === token)?.price ?? null
              ),
              token: token,
            };
          }
        ),
        celsius_tx_custody: celciusAccountTx.custody_account.map(
          ({ date, token, type, value }) => {
            return {
              TxsDate: date,
              Type: type,
              Value: value,
              Coin: Number(
                prices.find((price) => price.coin === token)?.price ?? null
              ),
              token: token,
            };
          }
        ),
        total_celsius_earn_balances: totalCelsiusEarnBalances,
        total_celsius_custody_balances: totalCelsiusCustodyBalances,
        total_celsius_witheld_balances: totalCelsiusWitheldBalances,
        total_celsius_borrow_balances: totalCelsiusBorrowBalances,
        earn_preference_risk: earnPreferenceRisk,
        custody_preference_risk: custodyPreferenceRisk,
        account_preference_risk: accountPreferenceRisk,

        ...(formData.transaction_earn_type === "Upload File"
          ? {
              celsius_excl_earn: formData?.celsius_excl_earn?.fileList?.map(
                (fileItem: any) => {
                  return {
                    ...fileItem,
                    content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
                  };
                }
              ),
            }
          : {}),

        ...(formData.transaction_earn_type !== "No Transaction"
          ? {
              celsius_pdf_earn: formData?.celsius_pdf_earn?.fileList?.map(
                (fileItem: any) => {
                  return {
                    ...fileItem,
                    content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
                  };
                }
              ),
            }
          : {}),

        ...(formData.transaction_custody_type === "Upload File"
          ? {
              celsius_excl_custody:
                formData?.celsius_excl_custody?.fileList?.map(
                  (fileItem: any) => {
                    return {
                      ...fileItem,
                      content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
                    };
                  }
                ),
            }
          : {}),

        ...(formData.transaction_custody_type !== "No Transaction"
          ? {
              celsius_pdf_custody: formData?.celsius_pdf_custody?.fileList?.map(
                (fileItem: any) => {
                  return {
                    ...fileItem,
                    content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
                  };
                }
              ),
            }
          : {}),

        is_poc_filed: formData?.is_poc_filed,

        ...(formData?.is_poc_filed === "Yes"
          ? {
              is_poc_general_bar: dayjs(
                new Date(formData?.is_poc_general_bar?.toString())
              ).toISOString(),
              poc_file_number: formData?.poc_file_number,
              name_appear_poc: formData?.name_appear_poc,
              is_poc_registered: formData?.is_poc_registered,
              poc_link: formData?.poc_link?.fileList?.map((fileItem: any) => {
                return {
                  ...fileItem,
                  content: baseURL + "/files?name=" + encodeURIComponent(fileItem.name)+ "&token=" + localStorage.getItem('token'),
                };
              }),
            }
          : {}),

        opnx_data: {
          accountId: localStorage.getItem("accountId"),
          applicationId: localStorage.getItem("applicationId"),
          claimType: CLAIM_TYPE,
        },
      };

      const claim_id = Math.random().toString(36).substring(2, 14);
      setClaimId(claim_id);

      await clientAPI("post", "/submitClaim", {
        claim_id,
        survey: JSON.stringify(bodyData),
        claim_type: CLAIM_TYPE,
      });
      getExceedLimit();
      setIsSubmitted(true);
    } catch (error) {}
  };

  const [celciusScheduleData, setCelciusScheduleData] =
    useState<ScheduleFLine>(DEFAULT_SCHEDULE);
  const [celciusAccountBalance, setCelciusAccountBalance] =
    useState<CelciusAccountBalance>(DEFAULT_ACCOUNT_BALANCE);
  const [celciusAccountTx, setCelciusAccountTx] =
    useState<CelciusAccountTx>(DEFAULT_ACCOUNT_TX);
  const [messageApi, messageContextHolder] = message.useMessage();
  const progress = [
    {
      title: "Step 01",
    },
    {
      title: "Step 02",
    },
    {
      title: "Step 03",
    },
    {
      title: "Step 04",
    },
    ...(formData?.is_soa_available === "No" ||
    (formData?.is_soa_available === "Yes" &&
      formData?.is_soa_amount_agreed === "No")
      ? [
          {
            title: "Step 05",
          },
        ]
      : []),
  ];

  const getKycInfo = async () => {
    try {
      const result = await clientAPI("post", "/kycInfo", {
        accountId,
        loginToken: token,
      });

      if (result.status !== "FAILED") {
        setUserData(result.message.data);
      } else {
        // window.open("/#/", "_self");
      }
    } catch (error) {}
  };

  const getClaimInfo = async () => {
    try {
      const result = await clientAPI("post", "/claimInfo", {
        accountId,
        loginToken: token,
      });

      if (result.status !== "FAILED") {
        setClaimInfo(result.message.data);
      } else {
        // window.open("/#/", "_self");
      }
    } catch (error) {}
  };

  const getExceedLimit = async () => {
    try {
      const accountId = localStorage.getItem("accountId");
      const token = localStorage.getItem("token");

      const responses = await clientAPI("post", "/verifyExceedLimit", {
        accountId: accountId,
        loginToken: token,
        claim_type: CLAIM_TYPE,
      });
      if (responses.status !== "FAILED") {
        setIsExceedLimit(Boolean(responses.message));
      }
    } catch (error) {}
  };

  const getCelciusScheduleData = async (line: string) => {
    if (!line) {
      setCelciusScheduleData(DEFAULT_SCHEDULE);
      return;
    }
    setClaimDataLoading(true);
    try {
      // messageApi.loading({
      //   key: "get_schedule_f_line_loading",
      //   content: "Waiting for data......",
      // });
      const result = await clientAPI("post", "/celciusSchedule/" + line);
      if (result.message === null) {
        throw new Error();
      }
      if (result.status !== "FAILED") {
        console.log("here");
        messageApi.success({
          key: "get_schedule_f_line_success",
          content: "Loaded SCHEDULE F LINE",
          duration: 2,
        });
        setCelciusScheduleData(result.message);
      } else {
        messageApi.destroy("get_schedule_f_line_loading");
        messageApi.error({
          key: "get_schedule_f_line_error",
          content: "Something wrong with your request",
          duration: 2,
        });
      }

      setClaimDataLoading(false);
    } catch (error) {
      setCelciusScheduleData(DEFAULT_SCHEDULE);
      setClaimDataLoading(false);
      messageApi.destroy("get_schedule_f_line_loading");
      messageApi.error({
        key: "get_schedule_f_line_error",
        content: "Not found SCHEDULE F LINE",
        duration: 2,
      });
    }
  };

  useEffect(() => {
    (async () => {
      fetch("prices.json")
        .then((res) => res.json())
        .then((data) => {
          setPrices(data);
        });
      fetch("countries.json")
        .then((res) => res.json())
        .then((data) => {
          setCountries(data);
        });

      getExceedLimit();
      getKycInfo();
      getClaimInfo();
    })();
  }, []);

  // useEffect(() => {
  //   getCelciusScheduleData("3.1.000001");
  // }, []);

  return (
    <ClaimContext.Provider
      value={{
        progress,
        currentStep,
        setCurrentStep,
        userData,
        formData,
        setFormData,
        handleNext,
        countries,
        messageContextHolder,
        useYupValidationResolver,
        celciusScheduleData,
        prices,
        celciusAccountBalance,
        handleBack,
        setCelciusAccountBalance,
        getCelciusScheduleData,
        celciusAccountTx,
        setCelciusAccountTx,
        claimDataLoading,
        handleUpdateForm,
        handleSubmit,
        claimId,
        submitted,
        isExceedLimit,
        resetForm,
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
};

export default ClaimContextProvider;
