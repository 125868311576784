import * as yup from "yup";
import { clientAPI } from "../../../api/client";

const step_1 = yup
  .object({
    contact_email: yup
      .string()
      .email("Please enter correct email")
      .required("Response Required"),
    country: yup.string().required("Response Required"),
  })
  .required("Response Required");

const step_2 = yup
  .object({
    is_soa_available: yup.string().required("Response required"),
    claim_link: yup.string().when("is_soa_available", (val, schema) => {
      if (val[0] === "Yes") {
        return yup
          .string()
          .required("Response Required")
          .test("validate ", "Not found SCHEDULE F LINE", async (value) => {
            if (!value) return false;
            const result = await clientAPI("post", "/celciusSchedule/" + value);
            if (result.status !== "FAILED" && result.message) {
              return true;
            } else {
              return false;
            }
          });
      }
      return yup.string().notRequired();
    }),
    is_soa_amount_agreed: yup
      .string()
      .when("is_soa_available", (val, schema) => {
        if (val[0] === "Yes") {
          return yup.string().required("Response required");
        }
        return yup.string().notRequired();
      }),
    celsius_statement: yup
      .object({
        file: yup.object(),
        fileList: yup.array(),
      })
      .test("validate", "Response Required", (val) => {
        if (val?.fileList?.length) return true;
        return false;
      })
      .required("Response required"),
  })
  .required("Response Required");

const step_3 = yup
  .object({
    celsius_email: yup
      .string()
      .email("Please enter correct email")
      .required("Response Required"),
    celsius_address: yup.string().required("Response Required"),
    celsius_relationship: yup
      .array()
      .min(1, "Response Required")
      .required("Response Required"),
    transaction_earn_type: yup.string().required("Response required"),
    celsius_excl_earn: yup
      .object()
      .when("transaction_earn_type", (val, schema) => {
        if (val[0] === "Upload File") {
          return yup
            .object({
              file: yup.object(),
              fileList: yup.array(),
            })
            .test("validate", "Response Required", (val) => {
              if (val?.fileList?.length) return true;
              return false;
            })
            .required("Response required");
        }
        return yup.object().notRequired();
      }),
    celsius_pdf_earn: yup
      .object()
      .when("transaction_earn_type", (val, schema) => {
        if (val[0] !== "No Transaction") {
          return yup
            .object({
              file: yup.object(),
              fileList: yup.array(),
            })
            .test("validate", "Response Required", (val) => {
              if (val?.fileList?.length) return true;
              return false;
            })
            .required("Response required");
        }
        return yup.object().notRequired();
      }),
    transaction_custody_type: yup.string().required("Response required"),
    celsius_excl_custody: yup
      .object()
      .when("transaction_custody_type", (val, schema) => {
        if (val[0] === "Upload File") {
          return yup
            .object({
              file: yup.object(),
              fileList: yup.array(),
            })
            .test("validate", "Response Required", (val) => {
              if (val?.fileList?.length) return true;
              return false;
            })
            .required("Response required");
        }
        return yup.object().notRequired();
      }),
    celsius_pdf_custody: yup
      .object()
      .when("transaction_custody_type", (val, schema) => {
        if (val[0] !== "No Transaction") {
          return yup
            .object({
              file: yup.object(),
              fileList: yup.array(),
            })
            .test("validate", "Response Required", (val) => {
              if (val?.fileList?.length) return true;
              return false;
            })
            .required("Response required");
        }
        return yup.object().notRequired();
      }),
  })
  .required("Response Required");

const step_4 = yup
  .object({
    is_poc_filed: yup.string().required("Response required"),
    is_poc_general_bar: yup.string().when("is_poc_filed", (val, schema) => {
      if (val[0] === "Yes") {
        return yup.string().required("Response required");
      }
      return yup.string().notRequired();
    }),
    poc_file_number: yup.number().when("is_poc_filed", (val, schema) => {
      if (val[0] === "Yes") {
        return yup.number().required("Response required");
      }
      return yup.number().notRequired();
    }),
    is_poc_registered: yup.string().when("is_poc_filed", (val, schema) => {
      if (val[0] === "Yes") {
        return yup.string().required("Response required");
      }
      return yup.string().notRequired();
    }),
    poc_link: yup.object().when("is_poc_filed", (val, schema) => {
      if (val[0] === "Yes") {
        return yup.object().required("Response required");
      }
      return yup.object().notRequired();
    }),
    name_appear_poc: yup.string().when("is_poc_filed", (val, schema) => {
      if (val[0] === "Yes") {
        return yup.string().required("Response required");
      }
      return yup.string().notRequired();
    }),
  })
  .required("Response Required");

const step_5 = yup.object({}).required("Response Required");

export { step_1, step_2, step_3, step_4, step_5 };
