import {
  Button,
  Collapse,
  Form,
  Input,
  Select,
  Tabs,
  TabsProps,
  Typography,
  Modal,
  Divider,
} from "antd";
import clsx from "clsx";
import styles from "../index.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useCallback, useContext, useMemo } from "react";
import {
  FTXClaimContext,
  ScheduleFLineAccount,
} from "../../ClaimContextProvider";
import CryptoIcon from "../../../../components/CryptoIcon";
import debounce from "lodash.debounce";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { BiChevronRight } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { CheckingTxt } from "../../../MyFTXClaimInfo/Components/CheckingTxt";

const Step3 = () => {
  const [modal, setModal] = useState(null);

  const {
    formData,
    prices,
    claimDataLoading,
    handleNext,
    handleBack,
    useYupValidationResolver,
    FTXScheduleData,
    handleUpdateForm,
    form,
    handleSubmit,
    submitLoading,
    accountBalance,
  } = useContext(FTXClaimContext);

  const values = form.getFieldsValue(true);
  const handleNextStep = async () => {
    try {
      const resValid = await form.validateFields([
        "ftx_email",
        "claim_f_line",
        "is_soa_details_agreed",
      ]);

      if (resValid) {
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBackStep = useCallback(() => {
    handleBack();
  }, [handleBack, handleUpdateForm]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Account Balances`,
    },
  ];

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = ({ file, fileList, event }: any) => {
    setDefaultFileList(fileList);
  };
  const getBalanceContent = (t: any, extraClass: any = "") => (
    <div className={clsx("divide-y divide-grey-100 sm:divide-y-0", extraClass)}>
      {t?.map((item: any, index: number) => {
        const icon = prices
          .find((price) => price.coin === item.tokenName)
          ?.coin?.toLowerCase();

        return (
          <div key={index} className="py-4 sm:py-0">
            <div className="flex items-center">
              {!!icon && (
                <div className="mr-2">
                  <CryptoIcon name={icon} />
                </div>
              )}
              <div className="text-sm font-semibold">
                {item.tokenName}&nbsp;&nbsp;
              </div>
            </div>
            <div className="mt-2.5 sm:mt-2 text-xs">
              {(+item.value).toFixed(12)}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className={clsx(styles.panel_container)}>
        <Collapse
          className={styles.ftx_panel_collapse}
          expandIconPosition={"end"}
          expandIcon={() => null}
          defaultActiveKey="claim_data"
        >
          <Collapse.Panel header="Claim Information" key="claim_data">
            <div className="p-5">
              <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
                <Typography.Text className={"text-sm font-medium"}>
                  Claim Ownership
                </Typography.Text>
                <div>
                  <Typography.Text className={"font-medium text-base"}>
                    {values?.ftx_claim_owner}
                  </Typography.Text>
                </div>
              </div>
              <Divider type="horizontal" className="my-4" />
              <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
                <Typography.Text className={"text-sm font-medium"}>
                  Registered FTX Email
                </Typography.Text>
                <div>
                  <Typography.Text className={"font-medium text-base"}>
                    {values?.ftx_email}
                  </Typography.Text>
                </div>
              </div>
              <Divider type="horizontal" className="my-4" />
              <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
                <Typography.Text className={"text-sm font-medium"}>
                  FTX Customer Code
                </Typography.Text>
                <div>
                  <Typography.Text className={"font-medium text-base"}>
                    {values?.claim_f_line}
                  </Typography.Text>
                </div>
              </div>
              <Divider type="horizontal" className="my-4" />
              <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
                <Typography.Text className={"text-sm font-medium"}>
                  Do you agree with claim details?
                </Typography.Text>
                <div>
                  <Typography.Text className={"font-medium text-base"}>
                    {values?.is_soa_details_agreed}
                  </Typography.Text>
                </div>
              </div>
              <Divider type="horizontal" className="my-4" />
              <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
                <Typography.Text className={"text-sm font-medium"}>
                  Eligible Claim Amount in USD
                </Typography.Text>
                <div>
                  <Typography.Text className={"font-medium text-base"}>
                    ${accountBalance}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
        <Collapse
              className={styles.ftx_panel_collapse}
              expandIconPosition={"end"}
              expandIcon={() => null}
              defaultActiveKey="claim_data"
            >
              <Collapse.Panel header="Claim Data" key="claim_data">
                <div
                  className={
                    "divide-y divide-grey-100 flex flex-col sm:flex-row sm:divide-y-0 " +
                    styles.claim_table
                  }
                >
                  <div className="flex flex-col w-2/3 gap-2">
                    <div className="text-[#111111] text-[12px] font-medium">
                      Unliquidated or Disputed Indicator
                    </div>
                    <div className="text-black text-[13px]">
                      <div
                        className={`flex items-center text-[#CF352A] w-fit rounded-[36px] border border-[#E2E2E2] px-2 py-1`}
                      >
                        <RxDotFilled color={"#CF352A"} />
                        {FTXScheduleData?.indicator?.join(',') || 'No'}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 gap-2">
                    <div className="text-[#111111] text-[12px] font-medium">
                      Earn Indicator
                    </div>
                    <div className="text-black text-[13px]">
                      <CheckingTxt text={FTXScheduleData?.earnIndicator}/>
                    </div>
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>
        <Collapse
          className={styles.ftx_panel_collapse}
          expandIconPosition={"end"}
          expandIcon={() => null}
          defaultActiveKey="claim_data"
        >
          <Collapse.Panel header="Account Balances" key="claim_data">
            <div>
              {FTXScheduleData ? (
                <>
                  <div className={"hidden sm:block"}>
                    {getBalanceContent(
                      FTXScheduleData?.tokenData,
                      styles.table_balance
                    )}
                  </div>

                  <div
                    className="flex sm:hidden items-center justify-between px-4 py-4 cursor-pointer border border-grey-200 rounded-bl-lg rounded-br-lg"
                    //  @ts-ignore
                    onClick={() =>
                      setModal({
                        //  @ts-ignore
                        key: "1",
                        label: "Account Balances",
                        children: getBalanceContent(FTXScheduleData?.tokenData),
                      })
                    }
                  >
                    <div className="text-sm font-semibold">View Detail</div>

                    <BiChevronRight className="text-primary-400 text-xl" />
                  </div>
                </>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
        <Modal
          // @ts-ignore
          title={<span className="text-xl">{modal?.label || "NA"}</span>}
          open={Boolean(modal)}
          onCancel={() => setModal(null)}
          footer={null}
        >
          {/* @ts-ignore */}
          {modal?.children || null}
        </Modal>
      </div>

      <div className={styles.navigation}>
        <Button onClick={handleBackStep}>Back</Button>
        <Button
          onClick={handleSubmit}
          loading={submitLoading || claimDataLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
export default Step3;
