import styles from "./index.module.scss";
import { Modal, Tooltip } from "antd";
import { RxDotFilled } from "react-icons/rx";
import TagOutline from "../../../components/Tag/TagOutline";
import { HiCheckCircle } from "react-icons/hi";
import { survey_obj, truncDigits } from "../../../common/utils";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";

interface IProps {
  handleCancel: any;
  isModalOpen: any;
  claim: any;
  prices: any;
}

function ClaimInfoModal({ handleCancel, isModalOpen, claim, prices }: IProps) {
  function claimClass(admin_status: any, survey: any) {
    return (
      <div className={""}>
        <div className="flex gap-2">
          <HiCheckCircle className={"text-[#31B43E] text-2xl self-center"} />
          <span className="text-[#666666] text-sm font-normal">
            General Unsecured
          </span>
        </div>
        <div className="flex gap-2">
          <HiCheckCircle className={"text-[#31B43E] text-2xl self-center"} />
          <span className="text-[#666666] text-sm font-normal">
            Non-Priority
          </span>
        </div>
      </div>
    );
  }
  function preference(survey: any) {
    return (
      <>
        {parseFloat(survey.totalWithdrawn) - parseFloat(survey.totalDeposit) <=
          0 ? (
          <>
            <div className="flex gap-2 items-center">
              <AiFillCloseCircle
                className={"text-[#F04438] text-2xl self-center"}
              />
              <span className="text-[#666666] text-sm font-normal">
                Not Flagged Flagged
              </span>
            </div>

            <br />
          </>
        ) : parseFloat(survey.totalWithdrawn) -
          parseFloat(survey.totalDeposit) >
          0 ? (
          <>
            <div className="flex gap-2 items-center">
              <HiCheckCircle
                className={"text-[#31B43E] text-2xl self-center"}
              />
              <span className="text-[#666666] text-sm font-normal">
                Flagged
              </span>
            </div>

            <div className="flex gap-2">
              <span>
                (Net:{" "}
                {truncDigits(
                  parseFloat(survey.totalWithdrawn) -
                  parseFloat(survey.totalDeposit),
                  2
                )}{" "}
                USD )
              </span>
              <Tooltip
                placement="topLeft"
                title={
                  "Net amount is Total Withdrawal - Total Deposit within the 90 day window between April 14, 2022 & July 13, 2022"
                }
              >
                <div className="self-center">
                  {" "}
                  <BsInfoCircle className="self-center cursor-pointer" />
                </div>
              </Tooltip>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <Modal
      footer={null}
      title="Claim Information"
      open={isModalOpen}
      onCancel={handleCancel}
      bodyStyle={{ width: "10px !Important" }}
    >
      <div className={styles.main_two}>
        <div className="flex flex-col gap-2">
          <span className="text-[#111111] font-semibold text-sm">
            Claim Classification
          </span>
          {/* <div className="flex text-[#31B43E] border py-1 px-5 border-[#E2E2E2] text-center w-fit rounded-[20px]">
            <RxDotFilled className="self-center" />
            <span className="font-semibold">YES</span>
          </div> */}
          {claimClass(claim.admin_status, survey_obj(claim.survey))}
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">Claim Type</span>
          <div className="flex gap-1">Earn</div>
        </div>

        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <span className="text-[#666666] font-medium text-sm">
              CUD status
            </span>
            <div className="flex ml-3">
              <Tooltip
                placement="topLeft"
                title={
                  "This denotes if a claim is Contingent, Unliquidated and/or Disputed"
                }
              >
                <div className="self-center">
                  {" "}
                  <BsInfoCircle className="self-center cursor-pointer" />
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="flex gap-1">
            <div className="flex gap-1 px-2 py-1 text-[#CF352A] border-[#E2E2E2] border rounded-[36px] w-fit">
              <RxDotFilled className="self-center" />
              <div className="text-xs font-semibold self-center">No</div>
            </div>
          </div>
        </div>

        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">
            Offset status
          </span>
          <div className="flex gap-1">
            <div className="flex gap-1 px-2 py-1 text-[#CF352A] border-[#E2E2E2] border rounded-[36px] w-fit">
              <RxDotFilled className="self-center" />
              <div className="text-xs font-semibold self-center">No</div>
            </div>
          </div>
        </div>

        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span className="text-[#666666] font-normal text-sm">
              Creditor's Relatioship with Celsius
            </span>
          </div>

          <div className="">
            {survey_obj(claim.survey).celsius_relationship
              ? survey_obj(claim.survey).celsius_relationship.map(
                (relationship: any) => (
                  <>
                    {relationship === "Depositor" ? (
                      <TagOutline color="#D63696">Depositor</TagOutline>
                    ) : relationship === "Vendor" ? (
                      <TagOutline color="#31B43E">Vendor</TagOutline>
                    ) : relationship === "Other" ? (
                      <TagOutline color="#1FB5D6">Other</TagOutline>
                    ) : null}
                    <br />
                  </>
                )
              )
              : "N/A"}
          </div>
        </div>

        <div className="border border-[#EDEDED] my-3" />
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">Location</span>
          <div className="">{survey_obj(claim.survey).country}</div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Creditor KYC Status
          </span>
          <div className="">{claim.kyc_status ? claim.kyc_status : "N/A"}</div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <span className="text-[#666666] font-normal text-sm">
              Proof of Ownership
            </span>
            <Tooltip
              placement="topLeft"
              title={
                "Use this Claim Number to look up the publicly shared Notice of Transfer establishing SPV ownership of given claim"
              }
            >
              <div>
                <BsInfoCircle className="cursor-pointer !text-[#13A1A1] mt-1 self-center" />
              </div>
            </Tooltip>
          </div>

          <div className="">N/A</div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Tokens Issued
          </span>
          <div className="">N/A</div>
        </div>
        <div className="flex flex-col gap-2 border-[#EDEDED] border rounded-md px-2 py-2">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <div className="text-[#13A1A1] font-semibold text-md">
                Common Tokens
              </div>
              <div className="text-[#666666] text-md font-normal">{
                claim?.token_minted ?
                  Number(+claim?.token_minted / 10**18).toFixed(2) :
                  0
              }</div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-[#13A1A1] font-semibold text-md">
                Preference Tokens
              </div>
              <div className="text-[#666666] text-md font-normal">N/A</div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-[#13A1A1] font-semibold text-md">Tx ID</div>
            <div className="flex gap-2">
              <div className="text-[#666666] text-md font-normal">
              {claim?.hash ?<a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_EXPLORER_URL}/${claim.hash}`} >
                    {claim.hash?.slice(0, 4)}...{claim.hash?.slice(
                      -6
                    )}
                  </a> : 'N/A'}
              </div>
            </div>
          </div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        {/* <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Account Balances Confirmed
          </span>
          <div className="flex text-[#CF352A] border py-1 px-5 border-[#E2E2E2] text-center w-fit rounded-[20px]">
            <RxDotFilled className="self-center" />
            <span className="font-semibold">No</span>
          </div>
        </div> */}
      </div>
    </Modal>
  );
}
ClaimInfoModal.propTypes = {};

export default ClaimInfoModal;
