import { Button, Divider, Typography } from "antd";
import { useContext, useCallback } from "react";
import { TbCopy } from "react-icons/tb";
import { ClaimContext } from "../../ClaimContextProvider";

const ClaimaintInformation = () => {
  const { formData, userData } = useContext(ClaimContext);

  const renderItem = useCallback(
    (item: string) => formData[`${item}`] || `N/A`,
    [formData]
  );

  return (
    <div className="">
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Preferred Communication Email
        </Typography.Text>
        <div className="flex">
          <Typography.Text className={"font-medium text-base"}>
            {renderItem("contact_email")}
          </Typography.Text>

          {!!formData["contact_email"] && (
            <Typography.Text
              className="flex justify-center items-center"
              copyable={{
                text: "ssss",
                icon: (
                  <TbCopy className={"text-[#03abab] text-lg self-center"} />
                ),
              }}
            />
          )}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Individual or a Corporation
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {userData?.accountType
              ? userData.accountType.charAt(0).toUpperCase() +
              userData.accountType.slice(1)
              : "N/A"}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"font-medium text-sm"}>
          Attention
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {userData?.attention || "N/A"}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Legal Name
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {userData?.legalName || "N/A"}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          US person/US corporation
        </Typography.Text>
        <div>
          {userData?.usEntity ? (
            <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
              • YES
            </div>
          ) : (
            <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
              • NO
            </div>
          )}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Country of Residency
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {renderItem("country")}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Registered Celsius Email
        </Typography.Text>

        <div className="flex">
          <Typography.Text className={"font-medium text-base"}>
            {renderItem("celsius_email")}
          </Typography.Text>

          {formData?.["celsius_email"] && (
            <Typography.Text
              className="flex justify-center items-center"
              copyable={{
                text: "ssss",
                icon: (
                  <TbCopy className={"text-[#03abab] text-lg self-center"} />
                ),
              }}
            />
          )}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Physical Address
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {renderItem("celsius_address")}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Phone number
        </Typography.Text>
        <div>
          <Typography.Text className={"font-medium text-base"}>
            {userData?.phone || "N/A"}
          </Typography.Text>
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-row justify-between max-[450px]:flex-col max-[450px]:gap-2 border-b-black">
        <Typography.Text className={"text-sm font-medium"}>
          Relationship with Celsius
        </Typography.Text>
        <div>
          {formData["celsius_relationship"].length > 0 ? (
            <div className="flex flex-col items-end gap-1">
              {formData["celsius_relationship"].map((rel: string) => (
                <Typography.Text className={"font-medium text-base"}>
                  {rel}
                </Typography.Text>
              ))}
            </div>
          ) : (
            <Typography.Text className={"font-medium text-base"}>
              N/A
            </Typography.Text>
          )}
        </div>
      </div>
    </div>
  );
};
export default ClaimaintInformation;
