import {
  Button,
  Collapse,
  Form,
  Input,
  Select,
  TabsProps,
  Typography,
  Modal,
} from "antd";
import clsx from "clsx";
import styles from "../index.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useCallback, useContext, useMemo } from "react";
import {
  FTXClaimContext,
  ScheduleFLineAccount,
} from "../../ClaimContextProvider";
import CryptoIcon from "../../../../components/CryptoIcon";
import debounce from "lodash.debounce";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { BiChevronRight } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { CheckingTxt } from "../../../MyFTXClaimInfo/Components/CheckingTxt";
import { useLocalStorage } from "usehooks-ts";

const Step2 = () => {
  const [modal, setModal] = useState(null);

  const {
    FTXScheduleData,
    formData,
    prices,
    getFTXScheduleData,
    claimDataLoading,
    handleNext,
    handleBack,
    useYupValidationResolver,
    handleUpdateForm,
    handleSubmit,
    submitLoading,
    form,
    isOpen, 
    setIsOpen
  } = useContext(FTXClaimContext);


  const handleNextStep = async () => {
    try {
      const resValid = await form.validateFields([
        "ftx_email",
        "claim_f_line",
        "is_soa_details_agreed",
      ]);

      if (resValid) {
        const values = form.getFieldsValue();
        if (!FTXScheduleData || values?.is_soa_details_agreed === "No") {
          handleSubmit();
          return;
        }
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBackStep = useCallback(() => {
    handleBack();
  }, [handleBack, handleUpdateForm]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Account Balances`,
    },
  ];

  const debouncedClaimLinkData = useMemo(() => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      getFTXScheduleData(e.target.value);
    };
    return debounce(handleChange, 500);
  }, [getFTXScheduleData]);

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = ({ file, fileList, event }: any) => {
    setDefaultFileList(fileList);
  };

  const onFocusInput = () => {
    if(isOpen === '') {
      setIsOpen(true)
    }
  };

  const getBalanceContent = (t: any, extraClass: any = "") => (
    <div className={clsx("divide-y divide-grey-100 sm:divide-y-0", extraClass)}>
      {t?.map((item: any, index: number) => {
        const icon = prices
          .find((price) => price.coin === item.tokenName)
          ?.coin?.toLowerCase();

        return (
          <div key={index} className="py-4 sm:py-0">
            <div className="flex items-center">
              {!!icon && (
                <div className="mr-2">
                  <CryptoIcon name={icon} />
                </div>
              )}
              <div className="text-sm font-semibold">
                {item.tokenName}&nbsp;&nbsp;
              </div>
            </div>
            <div className="mt-2.5 sm:mt-2 text-xs">
              {(+item.value).toFixed(12)}
            </div>
          </div>
        );
      })}
    </div>
  );

  const onCloseModal = () => {
    setIsOpen(false)
  };

  return (
    <div className="flex flex-col gap-2">
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl">
          Claim Details
        </Typography.Text>
        <Form.Item
          name={"ftx_email"}
          label="Email associated with registered FTX Account"
          rules={[
            { type: "email", message: "Please input valid email!" },
            { required: true },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          required
          name={"claim_f_line"}
          rules={[{ required: true }]}
          label="Enter your FTX Customer Code"
        >
          <Input
            onFocus={onFocusInput}
            onChange={(e) => {
              debouncedClaimLinkData(e);
            }}
          />
        </Form.Item>

        {!!FTXScheduleData && (
          <>
            <Collapse
              className={styles.ftx_panel_collapse}
              expandIconPosition={"end"}
              expandIcon={() => null}
              defaultActiveKey="claim_data"
            >
              <Collapse.Panel header="Claim Data" key="claim_data">
                <div
                  className={
                    "divide-y divide-grey-100 flex flex-col sm:flex-row sm:divide-y-0 " +
                    styles.claim_table
                  }
                >
                  <div className="flex flex-col w-2/3 gap-2">
                    <div className="text-[#111111] text-[12px] font-medium">
                      Unliquidated or Disputed Indicator
                    </div>
                    <div className="text-black text-[13px]">
                      <div
                        className={`flex items-center text-[#CF352A] w-fit rounded-[36px] border border-[#E2E2E2] px-2 py-1`}
                      >
                        <RxDotFilled color={"#CF352A"} />
                        {FTXScheduleData?.indicator?.join(",") || "No"}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 gap-2">
                    <div className="text-[#111111] text-[12px] font-medium">
                      Earn Indicator
                    </div>
                    <div className="text-black text-[13px]">
                      <CheckingTxt text={FTXScheduleData?.earnIndicator} />
                    </div>
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>
            <Collapse
              className={styles.ftx_panel_collapse}
              expandIconPosition={"end"}
              expandIcon={() => null}
              defaultActiveKey="claim_data"
            >
              <Collapse.Panel header="Account Balances" key="claim_data">
                <div>
                  {!!FTXScheduleData ? (
                    <>
                      <div className={"hidden sm:block"}>
                        {getBalanceContent(
                          FTXScheduleData?.tokenData,
                          styles.table_balance
                        )}
                      </div>

                      <div
                        className="flex sm:hidden items-center justify-between px-4 py-4 cursor-pointer border border-grey-200 rounded-bl-lg rounded-br-lg"
                        //  @ts-ignore
                        onClick={() =>
                          setModal({
                            //  @ts-ignore
                            key: "1",
                            label: "Account Balances",
                            children: getBalanceContent(
                              FTXScheduleData?.tokenData
                            ),
                          })
                        }
                      >
                        <div className="text-sm font-semibold">View Detail</div>

                        <BiChevronRight className="text-primary-400 text-xl" />
                      </div>
                    </>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </Collapse.Panel>
            </Collapse>
          </>
        )}
        <Modal
          // @ts-ignore
          title={<span className="text-xl">{modal?.label || "NA"}</span>}
          open={Boolean(modal)}
          onCancel={() => setModal(null)}
          footer={null}
        >
          {/* @ts-ignore */}
          {modal?.children || null}
        </Modal>
        {!!FTXScheduleData && (
          <Form.Item
            label="Do you agree with the claim information as published above?"
            required
            name={"is_soa_details_agreed"}
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Select"
            />
          </Form.Item>
        )}
      </div>

      <div className={styles.navigation}>
        <Button onClick={handleBackStep}>Back</Button>
        <Button
          onClick={handleNextStep}
          loading={submitLoading || claimDataLoading}
        >
          Next
        </Button>
      </div>
      <Modal
        open={isOpen}
        onCancel={onCloseModal}
        className={"modal-ftx-code"}
        title={<div className="title-txt">FTX Customer Code</div>}
        footer={null}
        centered
      >
        <div className="txt-desc">
          Please Note: Your FTX Customer Code is different from your FTX Account
          ID!
        </div>
        <div className="txt-desc">
          You can find your customer code in the email sent to you
        </div>
        <div className="txt-desc bold-txt">
          From: FTX Noticing {"<"}<span className="mail-txt">ftx@noticing.ra.kroll.com</span>{">"}
        </div>
        <div className="txt-desc bold-txt">
          Subject: FTX - Your Scheduled Claim Information and Unique Customer
          Code
        </div>
        <div className="flex justify-center">
          <button onClick={onCloseModal} className={"button"}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default Step2;
