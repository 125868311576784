import { Button, Form, Input, Select, Typography } from "antd";
import { useCallback, useContext, useEffect } from "react";
import USER_ICON from "../../../statics/images/user.svg";
import FILE_ICON from "../../../statics/images/fi_file-text.svg";
import PHONE_ICON from "../../../statics/images/fi_phone.svg";
import { ClaimContext } from "../ClaimContextProvider";
import styles from "./index.module.scss";
import clsx from "clsx";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { step_1 } from "./schema";

const Step1 = () => {
  const {
    userData,
    countries,
    handleNext,
    handleBack,
    useYupValidationResolver,
    formData,
    handleUpdateForm,
  } = useContext(ClaimContext);

  type FormData = yup.InferType<typeof step_1>;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(step_1),
    defaultValues: {
      contact_email: formData.contact_email,
      country: formData.country,
    },
  });

  const values = useWatch({ control });

  const handleNextStep = useCallback(() => {
    handleUpdateForm(values);
    handleNext();
  }, [handleNext, handleUpdateForm, values]);

  return (
    <Form onFinish={handleSubmit(handleNextStep)} layout="vertical">
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl">
          Personal Details
        </Typography.Text>
        <div className={styles["detail-information"]}>
          {userData && (
            <div className={styles["personal_info"]}>
              <div className="flex items-center gap-x-1">
                <img src={USER_ICON} alt="USER_ICON" />
                <p className="m-0">
                  Legal Name: <span>{userData.legalName}</span>
                </p>
              </div>
              {userData.accountType !== "INDIVIDUAL" && (
                <div className="flex items-center gap-x-1 mt-4">
                  <img src={FILE_ICON} alt="FILE_ICON" />
                  <p className="m-0">
                    Attention: <span>{userData.attention}</span>
                  </p>
                </div>
              )}
              <div className="flex items-center gap-x-1 mt-4">
                <img src={PHONE_ICON} alt="PHONE_ICON" />
                <p className="m-0">
                  Phone Number: <span>{userData.phone}</span>
                </p>
              </div>
              <ul className="m-0 mt-4">
                <li>
                  {userData.accountType === "INDIVIDUAL"
                    ? "Individual Account"
                    : "Corporate Account"}
                </li>

                
                  <li>
                  {!userData.usEntity && 'Not'} a US {' '}
                    {userData.accountType === "INDIVIDUAL"
                      ? "Individual"
                      : "Corporate"}
                  </li>
                
              </ul>
            </div>
          )}
        </div>
        <Form.Item label="Preferred Communication Email" required>
          <Controller
            name="contact_email"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <Input {...field} status={errors.contact_email && "error"} />
            )}
          />
          <Typography.Text type="danger">
            {errors.contact_email?.message?.toString()}
          </Typography.Text>
        </Form.Item>

        <Form.Item label="Country of Residency" required>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                status={errors.country && "error"}
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.name,
                }))}
                placeholder="Select"
                {...field}
              />
            )}
          />
          <Typography.Text type="danger">
            {errors.country?.message?.toString()}
          </Typography.Text>
        </Form.Item>
      </div>
      <div className={styles.navigation}>
        <Button htmlType="submit">Next</Button>
      </div>
    </Form>
  );
};
export default Step1;
